import React, { useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import PropTypes from 'prop-types';
import moment from "moment";

export function ActivitiesGraph(props) {
    const { activitiesList, undisplayedActivitiesList, isLoading, highlightedActivity } = props;
    const backgroundColour = "#0C2235";
    const undisplayedColourMain = "#EBEBEB";
    const undisplayedColourBackground = "#E0E0E0";
    const highlightedColourMain = "#E6ECF2";
    const maxTypeIdGraphHeight = 5;
    const baseXAxisHeight = 1;
    const [graphData, setGraphData] = useState({ stack1: [], stack2: [] });
    const noClipFirstXAxis = 30;
    const dateFormat = "YYYY-MM-DD HH:mm";

    function getBackgroundColour(isDisabled, isHighlighted) {
        if (isDisabled) return undisplayedColourBackground;
        if (isHighlighted) return highlightedColourMain;
        return backgroundColour;
    }

    function dateToLocalMoment(date) {
        var dateUtcMoment = moment.utc(date);
        var dateUtc = dateUtcMoment.toDate();
        return moment(dateUtc).local();
    }

    function formatData(data, undisplayedData) {

        let baseData = [];
        let timeFormattedData = [];
        data.map(a => {
            for (let i = 0; i < a.duration; i++) {
                baseData.push({
                    startDate: dateToLocalMoment(a.startDate).add(i, 'minutes').format(dateFormat),
                    typeId: a.typeId + baseXAxisHeight,
                    isHighlighted: a.activityId === highlightedActivity?.activityId,
                    isDisabled: false,
                    colour: a.bgColor
                });
            }
        });
        undisplayedData.map(a => {
            for (let i = 0; i < a.duration; i++) {
                baseData.push({
                    startDate: dateToLocalMoment(a.startDate).add(i, 'minutes').format(dateFormat),
                    typeId: a.typeId + baseXAxisHeight,
                    isHighlighted: false,
                    isDisabled: true,
                    colour: undisplayedColourMain,
                });
            }
        });
        //Add/amend values to make sure the graph shows 24 hours:
        let startDateOfGraph = data[0] ? dateToLocalMoment(data[0].startDate).format(dateFormat) : moment(props.baseDate).format(dateFormat);
        const startTime = props.isSummary ? moment(startDateOfGraph, dateFormat).startOf('day') : moment(startDateOfGraph, dateFormat);
        for (let i = 0; i < (24 * 60); i++) {
            timeFormattedData.push({ startDate: dateToLocalMoment(startTime).add(i, 'minutes').format(dateFormat), typeId: 0 });
        }
        timeFormattedData = timeFormattedData.map(r => {
            return Object.assign(r, baseData.find(b => b.startDate === r.startDate));
        });

        let stack1 = timeFormattedData.map(a => {
            return { ...a, colour: getBackgroundColour(a.isDisabled, a.isHighlighted) };
        });
        let stack2 = timeFormattedData.map(a => {
            return { ...a, typeId: (a.typeId === 0 ? 0 : maxTypeIdGraphHeight - a.typeId) };
        });
        return { stack1: stack1, stack2: stack2 };
    }

    useEffect(() => {
        setGraphData(formatData(activitiesList, undisplayedActivitiesList));
    }, [activitiesList, undisplayedActivitiesList, highlightedActivity]);

    const options = {
        grid: {
            left: 25,
            top: 0,
            right: 25,
            bottom: 35,
            tooltip: {
                show: false
            }
        },
        legend: {
            show: false
        },
        tooltip: {
            trigger: 'axis'
        },
        xAxis: {
            axisLabel: {
                align: 'left',
                interval: 0,
                formatter: function (value, index) {
                    if (index === 0 || (moment(value).isSame(moment(value).startOf('day')) && index > noClipFirstXAxis))
                        return "{firstItem|" + moment(value).format("HH:mm") + "\n" + String(moment(value).format("DD MMM")).toUpperCase() + "}";
                    if (moment(value).format("mm") === "00" && index > noClipFirstXAxis)
                        return moment(value).format("HH");
                    else return "";
                },
                rich: {
                    firstItem: {
                        fontWeight: 'bold'
                    }
                }
            },
            type: 'category',
            splitLine: {
                show: true,
                lineStyle: {
                    color: '#f3f3f3',
                    type: 'solid'
                },
                interval: 59
            },
            axisTick: {
                show: false
            },
            data: graphData.stack1.map(a => a.startDate),
        },
        yAxis: {
            type: 'value',
            axisLabel: {
                formatter: () => { }
            },
            splitLine: { show: false }
        },
        series: [
            {
                data: graphData.stack1.map(a => {
                    return {
                        value: a.typeId,
                        itemStyle: {
                            color: a.colour
                        }
                    }
                }),
                type: 'bar',
                barCategoryGap: '0%',
                stack: 'x',
                itemStyle: {
                    borderType: 'none'
                }
            },
            {
                data: graphData.stack2.map(a => {
                    return {
                        value: a.typeId,
                        itemStyle: {
                            color: a.colour
                        }
                    }
                }),
                type: 'bar',
                barCategoryGap: '0%',
                stack: 'x',
                itemStyle: {
                    borderType: 'none'
                }
            }
        ]
    };

    return (
        <ReactECharts
            option={options}
            notMerge={true}
            lazyUpdate={true}
            theme={"light"}
            style={{ height: "100%" }}
            showLoading={isLoading}
        />
    );
}
ActivitiesGraph.defaultProps = {
    activitiesList: [],
    undisplayedActivitiesList: [],
    isLoading: false,
    isSummary: false
};

ActivitiesGraph.propTypes = {
    activitiesList: PropTypes.array,
    undisplayedActivitiesList: PropTypes.array,
    isLoading: PropTypes.bool,
    isSummary: PropTypes.bool,
    baseDate: PropTypes.string,
    highlightedActivity: PropTypes.object
};
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Modal, NotificationToaster } from "components/notifications";
import { FormHeading, FormSelect } from "components/form-fields";
import { AssetStatus } from "components/status/AssetStatus";
import { DateToLocal } from "components/dates";
import { Button, ButtonSave } from "components/buttons";
import { Intent } from "@blueprintjs/core";
import { AssetService } from "services";
import { ActivitiesList } from "components/fleet";
import { AlertUnsavedData } from 'components/notifications/AlertUnsavedData';
import moment from "moment";

export function AssignJourneyModal(props) {
    const { showModal, onCloseModal, date, assetDetails, cardIwData } = props;
    const activityDateFormat = "YYYY-MM-DDTHH:mm:ss";

    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [title, setTitle] = useState(<></>);
    const [selectedJourneyType, setSelectedJourneyType] = useState(0);
    const [journeyTypes, setJourneyTypes] = useState([]);
    const [selectedActivitiesList, setSelectedActivitiesList] = useState([]);
    const [showDirtyDataAlert, setShowDirtyDataAlert] = useState(false);

    useEffect(() => {
        if (date && assetDetails && cardIwData) {
            setTitle(
                <div className="inline-items day-detail-modal">
                    <FormHeading headingLevel="h2" className="inline" isLoading={isLoading}>Assign Journey to </FormHeading>
                    <AssetStatus statusId={assetDetails.assetStatusId} ownershipId={assetDetails.assetOwnershipId}  statusAttributeMappings={assetDetails.statusAttributeMappings} ownershipAttributeMappings={assetDetails.ownershipAttributeMappings} />
                    <FormHeading headingLevel="h2" isLoading={isLoading}><a href={`${window.env.VISION_URL}/#/Fleet/RedirectToProfile?assetId=${assetDetails.assetId}`}>{assetDetails.assetRegistration}</a></FormHeading>
                    <FormHeading headingLevel="h2" isLoading={isLoading}>on <DateToLocal format='DD MMM YYYY'>{date}</DateToLocal></FormHeading>
                </div>
            );

            GetJourneyType();
            setIsLoading(false);
        }
    }, [date, assetDetails, cardIwData]);

    function GetJourneyType() {
        AssetService.getJourneyTypes().then((response) => {
            setJourneyTypes(response.map((item) => {
                return {
                    id: item.journeyTypeID,
                    name: item.journeyName
                }
            }));
        }).catch(() => {
            NotificationToaster.show(Intent.DANGER, "Failed to get journey types");
        });
    }

    function onJourneyTypeChange(item, originalValue) {
        if (originalValue !== null) {
            setSelectedJourneyType(item.id);
        }
    }

    function onActivityCheckChange(activityId, checked) {

        let selectedActivitiesTempList = selectedActivitiesList;

        //Adding or removing the activity from the selected activities list
        if (checked) {
            selectedActivitiesTempList = [...selectedActivitiesList, activityId];
        }
        else {
            selectedActivitiesTempList = selectedActivitiesTempList.slice(0, selectedActivitiesTempList.indexOf(activityId));
        }

        //If 2 or more activities are selected, then select all the activities between the first and last selected activities
        if (selectedActivitiesTempList.length >= 2 && checked) {

            let selectedActivitiesTempListIndex = selectedActivitiesTempList.map(x => cardIwData.activities.indexOf(cardIwData.activities.find(a => a.activityId === parseInt(x))));
            let minIndex = Math.min(...selectedActivitiesTempListIndex);
            let maxIndex = Math.max(...selectedActivitiesTempListIndex);
            selectedActivitiesTempList = cardIwData.activities.filter((x, i) => i >= minIndex && i <= maxIndex).map(x => x.activityId.toString());
        }

        setSelectedActivitiesList(selectedActivitiesTempList);
    }

    function selectAll(checked) {
        if (checked) {
            setSelectedActivitiesList(cardIwData.activities.map(x => x.activityId.toString()));
        }
        else {
            setSelectedActivitiesList([]);
        }
    }

    function saveJourney() {
        if (selectedJourneyType === 0) {
            NotificationToaster.show(Intent.DANGER, "Please select a journey type");
            return;
        }

        if (selectedActivitiesList.length === 0) {
            NotificationToaster.show(Intent.DANGER, "Please select activities");
            return;
        }

        setIsSaving(true);

        let filteredSelectedActivitiesList = cardIwData.activities.filter(x => selectedActivitiesList.includes(x.activityId.toString())).sort((a, b) => {
            let da = new Date(a.startDate),
                db = new Date(b.startDate);

            if (da > db) {
                return 1;
            }
            if (da < db) {
                return -1;
            }
            return 0;
        });

        let firstActivity = filteredSelectedActivitiesList[0];
        let lastActivity = filteredSelectedActivitiesList[filteredSelectedActivitiesList.length - 1];
        let minDate = moment(firstActivity.startDate).format(activityDateFormat);
        let maxDate = moment(lastActivity.startDate).add(lastActivity.duration, 'minutes').format(activityDateFormat);

        AssetService.assignJourney(assetDetails.assetId, selectedJourneyType, minDate, maxDate).then(() => {
            NotificationToaster.show(Intent.SUCCESS, "Journey assigned successfully");
            resetState();
            onCloseModal();
        }).catch(() => {
            NotificationToaster.show(Intent.DANGER, "Failed to assign journey");
            setIsSaving(false);
        });
    }

    function CloseAssignJourneyModal() {
        if (selectedJourneyType !== 0 || (selectedActivitiesList !== null && selectedActivitiesList.length > 0)) {
            setShowDirtyDataAlert(true);
        }
        else {
            resetState();
            onCloseModal();
        }
    }

    function handleConfirmLeave() {
        resetState();
        onCloseModal();
    }

    function handleCancelLeave() {
        setShowDirtyDataAlert(false);
    }

    function resetState() {
        setSelectedActivitiesList([]);
        setSelectedJourneyType(0);
        setJourneyTypes([]);
        setIsSaving(false);
        setShowDirtyDataAlert(false);
    }

    return (
        <Modal
            isOpen={showModal}
            onClose={CloseAssignJourneyModal}
            title={title}
            clickOutsideClose={true}
            extraWide={true}
            isCloseButtonShown={false}
            updateStateOnClose={false}
        >
            <AlertUnsavedData
                isDirty={showDirtyDataAlert}
                isDirtySamePage={showDirtyDataAlert}
                onConfirmLeave={handleConfirmLeave}
                onCancelLeave={handleCancelLeave}
            />
            <div>
                <FormHeading headingLevel="h3">Journey type</FormHeading>
                <FormSelect
                    id="journey-type"
                    loading={isLoading}
                    disabled={isSaving}
                    items={journeyTypes}
                    placeholder="Select a journey type"
                    selectedValue={selectedJourneyType}
                    onItemSelect={onJourneyTypeChange}
                    dangerHelperText=""
                ></FormSelect>
                <FormHeading headingLevel="h3">Activities</FormHeading>
                <p>Select the first and last activity to be assigned to this journey.</p>
                <ActivitiesList
                    id="assign-activities-list"
                    isLoading={false}
                    activitiesList={cardIwData ? cardIwData.activities : []}
                    selectable={true}
                    onActivityCheckChange={onActivityCheckChange}
                    selectedActivities={selectedActivitiesList}
                    selectAll={selectAll}
                />
                <div className="bp3-dialog-footer spacer-top">
                    <div className="bp3-dialog-footer-actions">
                        <Button
                            text="Cancel"
                            intent="secondary"
                            onClick={CloseAssignJourneyModal}
                        />
                        <ButtonSave
                            id="journey-type-edit"
                            disabled={isSaving}
                            onClick={saveJourney}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    )
}

AssignJourneyModal.defaultProps = {

}

AssignJourneyModal.propTypes = {
    showModal: PropTypes.bool.isRequired,
    onCloseModal: PropTypes.func.isRequired,
    date: PropTypes.string.isRequired,
    assetDetails: PropTypes.object.isRequired,
    cardIwData: PropTypes.object.isRequired
}
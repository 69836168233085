import React, { Fragment, useEffect, useState } from 'react';
import { Route, BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';
import classNames from "classnames";
import { useSelector } from 'react-redux';


//Pages
import { Account } from 'pages/account/edit';
import { AlertTypeListing } from 'pages/alert-type/listing';
import { LocationListing } from 'pages/locations/listing';
import { Location } from 'pages/locations/edit';
import { User, UserProfile } from 'pages/users/edit';
import { UserCreate } from 'pages/users/create';
import { LocationCreate } from 'pages/locations/create';
import { PolicyCreate } from 'pages/policy/create';
import { UploadDeclaration } from 'pages/policy/upload-declaration';
import { Page404 } from 'pages/errors/page-404';
import { AuthenticatedRoute } from 'components/user-access';
import { TopBar, LeftMenu, ShowHide } from 'components/layout';
import { UserListing } from 'pages/users/listing';
import { UserService } from 'services';
import { ExternalContactListing } from 'pages/external-contacts/listing';
import { ExternalContactCreate } from 'pages/external-contacts/create';
import { ExternalContactEdit } from 'pages/external-contacts/edit';
import { Policy } from 'pages/policy';
import { PolicyListing } from 'pages/policy/listing';
import { HierarchiesListing } from 'pages/locations/hierarchies/listing';
import { HierarchyCreate } from 'pages/locations/hierarchies/create';
import { Hierarchy } from 'pages/locations/hierarchies/edit';
import { ScheduleListing } from 'pages/schedules/listing';
import { ScheduleCreate } from 'pages/schedules/create';
import { Schedule } from 'pages/schedules/edit';
import { SelfReportingAdminListing } from 'pages/internal-admin/self-service-reports/listing';
import { SelfReportingAdminCreate } from 'pages/internal-admin/self-service-reports/create';
import { SelfReportingAdminEdit } from 'pages/internal-admin/self-service-reports/edit';
import { SelfServiceReports } from 'pages/self-service-reports';
import { GlobalSearch } from 'pages/internal-admin/global-search';
import { ErroredSchedules } from 'pages/internal-admin/errored-schedules';
import { JobReport } from 'pages/report';
import { NotificationToaster } from 'components/notifications';
import { Intent } from '@blueprintjs/core';
import { OcrPrevisit } from 'pages/ocr/previsit';
import { OcrCompleteReview } from 'pages/ocr/complete-previsit';
import { ReviewListing } from 'pages/review/listing';
import { EmployeeSettings } from 'pages/employee-settings/EmployeeSettings';
import { Dashboard } from '../dashboards';
import { ScheduledReportsListing } from 'pages/scheduled-reports/listing';
import { ScheduledReport } from 'pages/scheduled-reports/view';
import { Employee } from 'pages/employee/edit';
import { AlertListing } from 'pages/alert-type/alert/listing';
import { Asset } from 'pages/asset/edit';
import { AssetCreate } from 'pages/asset/create';
import { WalkaroundCheckListing } from 'pages/walkaround-check/listing';
import { DefectReportingDetails } from 'pages/walkaround-check/details';
import { VuCardViewer } from 'pages/card-viewer/vehicle-unit';
import { CustomDashboard } from 'pages/custom-dashboards/edit';
import { CustomDashboardListing } from 'pages/custom-dashboards/listing';
import { CustomDashboardCreate } from 'pages/custom-dashboards/add';
import { SystemMessageListing } from 'pages/internal-admin/system-messages/listing';
import { SystemMessageCreate } from 'pages/internal-admin/system-messages/create';
import { SystemMessageUpdate } from 'pages/internal-admin/system-messages/edit';
import { TachoFileViewer } from 'pages/tacho-file-viewer';
import { RiskSettings } from 'pages/risk/settings';
import { RiskOverview } from 'pages/risk/overview';
import { JobListing, JobAmendmentListing, JobCompletedListing } from 'pages/job/listing';
import { CollisionsListing } from 'pages/collisions/listing/CollisionsListing';
import { CollisionCreate } from 'pages/collisions/create';
import { CollisionEdit } from 'pages/collisions/edit';
import store from 'index';
import { Job } from 'pages/job/edit';
import { RiskEmployeeProfile, RiskEmployeeListing } from '../risk/employee';
import { CloseJobView } from 'pages/job/edit/close-job/View';
import { HomePage } from 'pages/home-page';
import { Fleet } from 'pages/fleet';
import { Calendar } from 'pages/fleet/calendar';
import { RiskAssetListing, RiskAssetProfile } from 'pages/risk/asset';

export function MainLayout(props) {
    const [loading, setLoading] = useState(true);
    const selfAdminDefaultActions = ["SelfAdmin:View", "Internal"];
    const ocrCompleteReviewActions = ["Internal:OCR", "ComplianceReview:View"];
    const alertActions = ['VISAlerts:View', 'TachoAlerts:View', 'Debrief:View', 'Training:View', 'DriverApp:Approve', 'HoldingArea:View', 'LicenceCheck:View', 'DriversHours:View', 'Employees:Import', 'Fleet:Import', 'PlannedMaintenance:Import', 'PrePlan:Import', 'Training:Import', 'Collisions:Import']
    const siteFullWidth = useSelector(state => state.site.fullWidth);

    useEffect(function () {
        UserService.getLoggedInUser()
            .then(() => {
                setLoading(false);
            }, () => {
                NotificationToaster.show(Intent.DANGER, "Sorry something has gone wrong. Please try refreshing the page.");
            });
    }, []);

    return (
        <Fragment>
            <ShowHide
                evaluator={!loading}
                show={(
                    <Router>

                        <TopBar environment={window.env.DISPLAY_ENVIRONMENT} />
                        <LeftMenu />
                        <div id="main-container" className={classNames({ "full-width": siteFullWidth })}>

                            <Switch>
                                <AuthenticatedRoute path="/account" action={selfAdminDefaultActions}>
                                    <Account breadcrumbs={[{ location: "/account", title: "Account" }]} />
                                </AuthenticatedRoute>
                                <AuthenticatedRoute path="/alert-type" action={alertActions}>
                                    <AlertTypeListing breadcrumbs={[{ location: "/alert-type", title: "Alert Types" }]} />
                                </AuthenticatedRoute>
                                <AuthenticatedRoute exact path="/location" action={selfAdminDefaultActions}>
                                    <LocationListing breadcrumbs={[{ location: "/location", title: "Location Management" }]} />
                                </AuthenticatedRoute>
                                <AuthenticatedRoute path="/location/:id(\d+)" action={selfAdminDefaultActions}>
                                    <Location breadcrumbs={[{ location: "/location", title: "Location Management" }, { title: "Edit Location" }]} />
                                </AuthenticatedRoute>
                                <AuthenticatedRoute exact path="/location/create" action={selfAdminDefaultActions}>
                                    <LocationCreate breadcrumbs={[{ location: "/location", title: "Location Management" }, { title: "Add Location" }]} />
                                </AuthenticatedRoute>
                                <AuthenticatedRoute exact path="/location/hierarchies" action={selfAdminDefaultActions}>
                                    <HierarchiesListing breadcrumbs={[{ location: "/location", title: "Location Management" }, { title: "Location Hierarchies" }]} />
                                </AuthenticatedRoute>
                                <AuthenticatedRoute path="/location/hierarchies/:id(\d+)" action={selfAdminDefaultActions}>
                                    <Hierarchy breadcrumbs={[{ location: "/location", title: "Location Management" }, { location: "/location/hierarchies", title: "Location Hierarchies" }, { title: "Edit Hierarchy" }]} />
                                </AuthenticatedRoute>
                                <AuthenticatedRoute exact path="/location/hierarchies/create" action={selfAdminDefaultActions}>
                                    <HierarchyCreate breadcrumbs={[{ location: "/location", title: "Location Management" }, { location: "/location/hierarchies", title: "Location Hierarchies" }, { title: "Add Location Hierarchy" }]} />
                                </AuthenticatedRoute>
                                <AuthenticatedRoute exact path="/user" action={selfAdminDefaultActions}>
                                    <UserListing breadcrumbs={[{ location: "/user", title: "User Management" }]} />
                                </AuthenticatedRoute>
                                <AuthenticatedRoute exact path="/external-contact" action={"Users:ExternalContacts:Manage"}>
                                    <ExternalContactListing breadcrumbs={[{ location: "/external-contact", title: "External Contact Management" }]} />
                                </AuthenticatedRoute>
                                <AuthenticatedRoute path="/external-contact/:id(\d+)" action={"Users:ExternalContacts:Manage"}>
                                    <ExternalContactEdit breadcrumbs={[{ location: "/external-contact", title: "External Contact  Management" }, { title: "Edit External Contact" }]} />
                                </AuthenticatedRoute>
                                <AuthenticatedRoute exact path="/external-contact/create" action={"Users:ExternalContacts:Manage"}>
                                    <ExternalContactCreate breadcrumbs={[{ location: "/external-contact", title: "External Contact  Management" }, { title: "Add External Contact" }]} />
                                </AuthenticatedRoute>
                                <Redirect exact from={"/user/" + store.getState().loggedInUser?.userId} to="/user/profile" />
                                <AuthenticatedRoute path="/user/:id(\d+)" action={selfAdminDefaultActions}>
                                    <User breadcrumbs={[{ location: "/user", title: "User  Management" }, { title: "Edit User" }]} />
                                </AuthenticatedRoute>
                                <AuthenticatedRoute exact path="/user/create" action={selfAdminDefaultActions}>
                                    <UserCreate breadcrumbs={[{ location: "/user", title: "User Management" }, { title: "Add User" }]} />
                                </AuthenticatedRoute>
                                <AuthenticatedRoute exact path="/schedule" action={selfAdminDefaultActions}>
                                    <ScheduleListing breadcrumbs={[{ location: "/schedule", title: "Schedules Management" }]} />
                                </AuthenticatedRoute>
                                <AuthenticatedRoute exact path="/schedule/create" action={selfAdminDefaultActions}>
                                    <ScheduleCreate breadcrumbs={[{ location: "/schedule", title: "Schedule Management" }, { title: "Add Schedule" }]} />
                                </AuthenticatedRoute>
                                <AuthenticatedRoute path="/schedule/:id(\d+)" action={selfAdminDefaultActions}>
                                    <Schedule breadcrumbs={[{ location: "/schedule", title: "Schedule Management" }, { title: "Edit Schedule" }]} />
                                </AuthenticatedRoute>
                                <AuthenticatedRoute path="/self-service-reports" action="SelfService:View">
                                    <SelfServiceReports breadcrumbs={[{ location: "/self-service-reports", title: "Self Service Reports" }]} />
                                </AuthenticatedRoute>
                                <AuthenticatedRoute exact path="/policy" action={selfAdminDefaultActions}>
                                    <PolicyListing breadcrumbs={[{ location: "/policy", title: "Policy Management" }]} />
                                </AuthenticatedRoute>
                                <AuthenticatedRoute path="/scheduled-reports/:id(\d+)" action="ScheduleReports:View">
                                    <ScheduledReport breadcrumbs={[{ location: "/scheduled-reports", title: "Scheduled Reports" }, { title: "View Reports" }]} />
                                </AuthenticatedRoute>
                                <AuthenticatedRoute exact path="/scheduled-reports" action="ScheduleReports:View">
                                    <ScheduledReportsListing breadcrumbs={[{ location: "/scheduled-reports", title: "Scheduled Reports" }]} />
                                </AuthenticatedRoute>
                                <AuthenticatedRoute path="/policy/:id(\d+)" action={selfAdminDefaultActions}>
                                    <Policy breadcrumbs={[{ location: "/policy", title: "Policy  Management" }, { title: "Edit Policy" }]} />
                                </AuthenticatedRoute>
                                <AuthenticatedRoute exact path="/policy/create" action={selfAdminDefaultActions}>
                                    <PolicyCreate breadcrumbs={[{ location: "/policy", title: "Policy Management" }, { title: "Add Policy" }]} />
                                </AuthenticatedRoute>
                                <AuthenticatedRoute exact path="/policy/upload-declaration" action={selfAdminDefaultActions}>
                                    <UploadDeclaration breadcrumbs={[{ location: "/policy", title: "Policy Management" }, { title: "Upload Declaration" }]} />
                                </AuthenticatedRoute>
                                <AuthenticatedRoute path="/employee-settings" action={selfAdminDefaultActions}>
                                    <EmployeeSettings breadcrumbs={[{ location: "/employee-settings", title: "Employee Settings" }]} />
                                </AuthenticatedRoute>
                                <AuthenticatedRoute path="/internal-admin/global-search" action="Internal">
                                    <GlobalSearch breadcrumbs={[{ title: "Global Search" }]} />
                                </AuthenticatedRoute>
                                <AuthenticatedRoute path="/internal-admin/errored-schedules" action="Internal">
                                    <ErroredSchedules breadcrumbs={[{ title: "Errored Schedules" }]} />
                                </AuthenticatedRoute>
                                <AuthenticatedRoute path="/report/:id(\d+)" action="EmployeeAssessment:View">
                                    <JobReport />
                                </AuthenticatedRoute>
                                <AuthenticatedRoute path="/internal-admin/self-service-reports/create" action="Internal">
                                    <SelfReportingAdminCreate breadcrumbs={[{ location: "/internal-admin/self-service-reports", title: "Self Reporting Admin" }, { title: "Create Report" }]} />
                                </AuthenticatedRoute>
                                <AuthenticatedRoute path="/internal-admin/self-service-reports/:id(\d+)" action="Internal">
                                    <SelfReportingAdminEdit breadcrumbs={[{ location: "/internal-admin/self-service-reports", title: "Self Reporting Admin" }, { title: "Edit Report" }]} />
                                </AuthenticatedRoute>
                                <AuthenticatedRoute path="/internal-admin/self-service-reports" action="Internal">
                                    <SelfReportingAdminListing breadcrumbs={[{ location: "/internal-admin/self-service-reports", title: "Self Reporting Admin" }]} />
                                </AuthenticatedRoute>
                                <AuthenticatedRoute exact path="/internal-admin/system-messages" action="Internal">
                                    <SystemMessageListing breadcrumbs={[{ location: "/internal-admin/system-messages", title: "System Message Management" }]} />
                                </AuthenticatedRoute>
                                <AuthenticatedRoute exact path="/internal-admin/system-messages/create" action="Internal">
                                    <SystemMessageCreate breadcrumbs={[{ location: "/internal-admin/system-messages", title: "System Message Management" }, { title: "Create System Message" }]} />
                                </AuthenticatedRoute>                
                                <AuthenticatedRoute path="/internal-admin/system-messages/:id(\d+)" action="Internal">
                                    <SystemMessageUpdate breadcrumbs={[{ location: "/internal-admin/system-messages", title: "System Message Management" }, { title: "Edit System Message" }]} />
                                </AuthenticatedRoute> 
                                <AuthenticatedRoute path="/upcoming-visits" action="ScheduledAppointment:View">
                                    <JobListing title="Upcoming Visits" administrationMode={false} requiredAction="ScheduledAppointment:View"/>
                                </AuthenticatedRoute> 
                                <AuthenticatedRoute path="/job-scheduling" action="Internal">
                                    <JobAmendmentListing title="Job Scheduling" requiredAction="Internal" />
                                </AuthenticatedRoute> 
                                <AuthenticatedRoute path="/completed-visits/:id(\d+)" action="AuditsInspections:View">
                                    <CloseJobView breadcrumbs={[{ location: "/completed-visits", title: "Completed visits" }, { title: "Completed visit" }]} baseUrl="/completed-visits-view" />
                                </AuthenticatedRoute>
                                <AuthenticatedRoute path="/completed-visits" action="AuditsInspections:View">
                                    <JobCompletedListing title="Completed Visits" requiredAction="AuditsInspections:View" />
                                </AuthenticatedRoute> 
                                <AuthenticatedRoute path="/job-administration" action="Internal">
                                    <JobListing title="Job Administration" administrationMode={true} requiredAction="Internal"/>
                                </AuthenticatedRoute>
                                <AuthenticatedRoute path="/job/scheduling-view/:jobId(\d+)" action="Internal:Jobscheduler">
                                    <Job breadcrumbs={[{ location: "/job-scheduling", title: "job scheduling" }, { title: "Job" }]} baseUrl="/scheduling-view" />
                                </AuthenticatedRoute>
                                <AuthenticatedRoute path="/job/:jobId(\d+)" action="Internal">
                                    <Job breadcrumbs={[{ location: "/job-administration", title: "job administration" }, { title: "Job" }]} baseUrl="" />
                                </AuthenticatedRoute>
                                <AuthenticatedRoute path="/reviews/compliance-review/pre-visit/:jobId(\d+)" action="Internal:OCR">
                                    <OcrPrevisit breadcrumbs={[{ location: "/reviews", title: "Compliance Review" }, { title: "Pre-visit Settings" }]} />
                                </AuthenticatedRoute>
                                <AuthenticatedRoute path="/reviews/compliance-review/:jobId(\d+)" action={ocrCompleteReviewActions}>
                                    <OcrCompleteReview breadcrumbs={[{ location: "/reviews", title: "Compliance Review" }, { title: "Complete" }]} />
                                </AuthenticatedRoute>
                                <AuthenticatedRoute path="/reviews" action={ocrCompleteReviewActions}>
                                    <ReviewListing breadcrumbs={[{ location: "/reviews", title: "Reviews" }, { title: "Review List" }]} />
                                </AuthenticatedRoute>
                                <AuthenticatedRoute path="/alert/:alertTypeKey">
                                    <AlertListing breadcrumbs={[{ location: "/alert-type", title: "Alert Types", externalLink: true }, { title: "Alert List" }]} />
                                </AuthenticatedRoute>
                                <AuthenticatedRoute path="/dashboards/custom/create" action={["internal"]}>
                                    <CustomDashboardCreate breadcrumbs={[{ location: "/dashboards/custom", title: "Custom Dashboards" }, { title: "Create Custom Dashboard" }]} />
                                </AuthenticatedRoute>
                                <AuthenticatedRoute exact path="/dashboards/custom" action={["internal"]}>
                                    <CustomDashboardListing breadcrumbs={[{ location: "/dashboards/custom", title: "Custom Dashboards" }]} />
                                </AuthenticatedRoute>
                                <AuthenticatedRoute path="/dashboards/custom/:id(\d+)" action={["internal"]}>
                                    <CustomDashboard breadcrumbs={[{ location: "/dashboards/custom", title: "Custom Dashboards" }, { title: "View Custom Dashboard" }]} />
                                </AuthenticatedRoute>
                                <AuthenticatedRoute path="/dashboards/:id">
                                    <Dashboard />
                                </AuthenticatedRoute>
                                <AuthenticatedRoute path="/employee/:id(\d+)" action={["Employees:View", "AuthorisedDriver"]}>
                                    <Employee breadcrumbs={[{ location: `${window.env.VISION_URL}/#/Drivers/`, title: "Manage My Employees", externalLink: true }, { title: "Profile" }]} />
                                </AuthenticatedRoute>
                                <AuthenticatedRoute path="/asset/create/business-area/:businessArea(\d+)" action={["Fleet:Add", "FleetPCN:Add", "FleetVIS:Add"]}>
                                    <AssetCreate breadcrumbs={[{ location: `${window.env.VISION_URL}/#/Fleet/`, title: "Manage My Fleet", externalLink: true }, { title: "Add Asset" }]} />
                                </AuthenticatedRoute>
                                <AuthenticatedRoute path="/asset/:id(\d+)" action={["Fleet:View", "FleetPCN:View", "FleetVIS:View"]}>
                                    <Asset breadcrumbs={[{ location: `${window.env.VISION_URL}/#/Fleet/`, title: "Manage My Fleet", externalLink: true }, { title: "Profile" }]} />
                                </AuthenticatedRoute>
                                <AuthenticatedRoute path="/walkaround-checks/:id(\d+)" action={["WalkaroundCheck:View"]}>
                                    <DefectReportingDetails breadcrumbs={[{ location: "", title: "" }, { title: "Walkaround check" }]} />
                                </AuthenticatedRoute>
                                <AuthenticatedRoute path="/walkaround-checks" action={["WalkaroundCheck:View"]}>
                                    <WalkaroundCheckListing breadcrumbs={[{ title: "Walkaround checks" }]} />
                                </AuthenticatedRoute>
                                <AuthenticatedRoute path="/card-viewer/vehicle-unit" action={["Internal"]}>
                                    <VuCardViewer breadcrumbs={[{ location: "/card-viewer/vehicle-unit", title: "Vehicle Unit Card Viewer" }]} />
                                </AuthenticatedRoute>
                                <AuthenticatedRoute path="/risk-settings" action={["Risk:Manage"]}>
                                    <RiskSettings breadcrumbs={[{ location: "/risk", title: "Manage my Risk" }, { title: "Risk Settings" }]} />
                                </AuthenticatedRoute>
                                <AuthenticatedRoute exact path="/risk/employee" action={["Risk:View"]} >
                                    <RiskEmployeeListing breadcrumbs={[{ location: "/risk", title: "Manage my Risk" }, { title: "Employee Risk" }]} />
                                </AuthenticatedRoute>
                                <AuthenticatedRoute exact path="/risk/asset" action={["Risk:View"]} >
                                    <RiskAssetListing breadcrumbs={[{ location: "/risk", title: "Manage my Risk" }, { title: "Asset Risk" }]} />
                                </AuthenticatedRoute>
                                <AuthenticatedRoute path="/risk/employee/:id(\d+)" action={["Risk:View"]}>
                                    <RiskEmployeeProfile breadcrumbs={[{ location: "/risk", title: "Manage my Risk" }, { location: "/risk/employee", title: "Employee Risk" }, { title: "Employee Profile" }]} />
                                </AuthenticatedRoute>
                                <AuthenticatedRoute path="/risk/asset/:id(\d+)" action={["Risk:View"]}>
                                    <RiskAssetProfile breadcrumbs={[{ location: "/risk", title: "Manage my Risk" }, { location: "/risk/asset", title: "Asset Risk" }, { title: "Asset Profile" }]} />
                                </AuthenticatedRoute>
                                <AuthenticatedRoute path="/risk" action={["Risk:View"]}>
                                    <RiskOverview breadcrumbs={[{ title: "Manage my Risk" }]} />
                                </AuthenticatedRoute>
                                <AuthenticatedRoute path="/tacho-file-viewer" action={["Internal"]}>{/*This can be DCVU:Import*/}
                                    <TachoFileViewer breadcrumbs={[{ title: "Tacho File Viewer" }]} />
                                </AuthenticatedRoute>
                                <AuthenticatedRoute path="/collisions/:id(\d+)" action={["Collisions:Import"]}>
                                    <CollisionEdit breadcrumbs={[{ location: "/collisions", title: "Collisions" }, { title: "Edit Collision" }]} />
                                </AuthenticatedRoute>
                                <AuthenticatedRoute path="/collisions/create" action={["Collisions:Import"]}>
                                    <CollisionCreate breadcrumbs={[{ location: "/collisions", title: "Collisions" }, { title: "Add Collision" }]} />
                                </AuthenticatedRoute>
                                <AuthenticatedRoute path="/collisions" action={["Collisions:View"]}>
                                    <CollisionsListing breadcrumbs={[{ title: "Collisions" }]} />
                                </AuthenticatedRoute>
                                <AuthenticatedRoute path="/fleet/:id(\d+)/Calendar" action={["Fleet:View", "FleetPCN:View", "FleetVIS:View"]}>
                                    <Calendar breadcrumbs={[{ location: "/Fleet", title: "Manage My Fleet" }, { title: "Calendar" }]} />
                                </AuthenticatedRoute>
                                <AuthenticatedRoute path="/fleet" action={["Fleet:View"]}>
                                    <Fleet breadcrumbs={[{ title: "Manage My Fleet" }]} />
                                </AuthenticatedRoute>
                                <AuthenticatedRoute path="/Home">
                                    <HomePage breadcrumbs={[{ title: "Home" }]} />
                                </AuthenticatedRoute>
                                <Route path="/user/profile">
                                    <UserProfile breadcrumbs={[{ title: "User Profile" }]} />
                                </Route>
                                <Route exact path="/" render={() => (<Redirect to="/Home" />)} />
                                <Route path="*" component={Page404} />
                            </Switch>
                        </div>

                    </Router>
                )}
            />

        </Fragment>

    );
}
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { UserAccess } from "components/user-access";
import { PageRestricted } from "pages/errors/page-restricted";
import { Button } from "components/buttons"
import { NotificationToaster, NotificationInline, Modal } from "components/notifications";
import { Card, Intent, MenuItem } from "@blueprintjs/core";
import { AssetService, BlobStorageService, LinkService } from 'services';
import classNames from "classnames";
import moment from "moment";
import { ReportList } from "components/reporting/ReportList";
import { Breadcrumbs } from 'components/navigation';
import { useParams } from "react-router-dom";
import { Dropdown } from "components/dropdown";
import { CalendarKeyModal } from "../calendar-key-modal";
import { FormDatePeriodSelector, FormCheckbox, FormHeading } from "components/form-fields"
import { CalendarCell } from "components/calendar/CalendarCell";
import { ShowHide } from "../../../components/layout/ShowHide";
import { DayDetailModal } from "../day-detail-modal";
import { AssetStatus } from 'components/status/AssetStatus';
import { ListingTable } from "components/listing";
import { Tooltip } from 'components/tooltip';
import { SetFiltering } from 'state/actions';

import './Calendar.css';

export function Calendar(props) {

    const dispatch = useDispatch();

    const requiredActions = ["Fleet:View", "FleetVis:View", "FleetPCN:View"];
    const daysOfWeek = ["MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY", "SUNDAY"];
    const { id } = useParams();
    const initialDate = moment().startOf('isoWeek').add('-2', 'weeks');
    const [calendarData, setCalendarData] = useState(null);
    const [reportDownloading, setReportDownloading] = useState(false);
    const [initialising, setInitialising] = useState(true);
    const [loading, setLoading] = useState(true);
    const [loadingGetFile, setLoadingGetFile] = useState(false);
    const [reduxLoaded, setReduxLoaded] = useState(false);
    const [initialPeriodOffset, setInitialPeriodOffset] = useState(null);
    const [uploadDownloading, setUploadingDownloading] = useState(false);
    const [startDate, setStartDate] = useState(initialDate);
    const [checkedWeeks, setCheckedWeeks] = useState([]);
    const [assetCalendarJsx, setAssetCalendarJsx] = useState(null);
    const [uploadModalJsx, setUploadModalJsx] = useState(null);
    const [activityTypes, setActivityTypes] = useState(null);
    const [showPreferencesModal, setShowPreferencesModal] = useState(false);
    const [showUploadsModal, setShowUploadsModal] = useState(false);
    const [uploadsModalTitle, setUploadsModalTitle] = useState("");
    const [uploadsModalDayId, setUploadsModalDayId] = useState(null);
    const [showTimes, setShowTimes] = useState(true);
    const [lastSearchPayload] = useState(null);
    const calendarDays = 28;
    const tachoWeeksToShow = 4;
    const daysInWeek = 7;
    const minutesInDay = 1440;
    const maxCommentLength = 255;
    const periodSelectorDateFormat = "DD MMM YYYY";
    const fleetDateReduxName = "fleetDate";
    const reduxDate = useSelector(state => state.filters[fleetDateReduxName]);

    //Dashboard General
    const [isWeekChanging, setIsWeekChanging] = useState(false);
    const [selectedDates, setSelectedDates] = useState([]);
    const [selectedActivity, setSelectedActivity] = useState(null);
    const [allActivities, setAllActivities] = useState([]);
    const [isFirstActivity, setIsFirstActivity] = useState(false);
    const [isLastActivity, setIsLastActivity] = useState(false);

    //Vehicle Activity State
    const [showVehicleActivity, setShowVehicleActivity] = useState(false);
    const [driveNumHours, setDriveNumHours] = useState(0);
    const [driveNumMinutes, setDriveNumMinutes] = useState(0);
    const [workNumHours, setWorkNumHours] = useState(0);
    const [workNumMinutes, setWorkNumMinutes] = useState(0);
    const [restNumHours, setRestNumHours] = useState(0);
    const [restNumMinutes, setRestNumMinutes] = useState(0);
    const [availableNumHours, setAvailableNumHours] = useState(0);
    const [availableNumMinutes, setAvailableNumMinutes] = useState(0);
    const [entryDurationNumHours, setEntryDurationNumHours] = useState(0);
    const [entryDurationNumMinutes, setEntryDurationNumMinutes] = useState(0);
    const [utilisedPercentage, setUtilisedPercentage] = useState(0);
    const [distanceTotal, setDistanceTotal] = useState(0);
    const [distanceNoCard, setDistanceNoCard] = useState(0);
    const [openingOdometerReading, setOpeningOdometerReading] = useState(0);
    const [closingOdometerReading, setClosingOdometerReading] = useState(0);

    //Uploads State
    const [showUploads, setShowUploads] = useState(true);
    const [lastVehicleUpload, setLastVehicleUpload] = useState(new Date());
    const [lastActivityOnVehicleDownload, setLastActivityOnVehicleDownload] = useState(new Date());

    //Drivers Used State
    const [showDriversUsed, setShowDriversUsed] = useState(true);
    const [driversUsed, setDriversUsed] = useState([]);
    const [numDriversUsed, setNumDriversUsed] = useState(0);

    // Key Modal state
    const [showKeyModal, setShowKeyModal] = useState(false);

    const [statusAttributeMappings, setStatusAttributeMappings] = useState([]);
    const [calendarIconsForKeyModal, setCalendarIconsForKeyModal] = useState([]);
    const [vehicleActivitiesForKeyModal, setVehicleActivitiesForKeyModal] = useState([]);
    const [ownershipAttributeMappings, setOwnershipAttributeMappings] = useState([]);


    // Day Detail Modal
    const [showDayDetailModal, setShowDayDetailModal] = useState(false);
    const [dayDetailModalAssetId, setDayDetailModalAssetId] = useState(0);
    const [dayDetailModalDate, setDayDetailModalDate] = useState("");
    const [ownershipForKeyModal, setOwnershipForKeyModal] = useState([])
    const [assetStatusesForKeyModal, setAssetStatusesForKeyModal] = useState([])
    const [dayDetailOpenTab, setDayDetailOpenTab] = useState(null);


    useEffect(() => {
        const { _isFirstActivity, _isLastActivity } = getActivityPositions(selectedActivity, allActivities);
        setIsFirstActivity(_isFirstActivity);
        setIsLastActivity(_isLastActivity);
    }, [selectedActivity, allActivities]);

    useEffect(() => {
        if (selectedActivity && calendarData) {
            if (selectedDates.length > 0) {
                setSelectedDates([]);
            }

            const summary = calculateActivitySummaryForActivity(selectedActivity);
            const _totalDistance = selectedActivity.summary.distance;
            const _distanceNoCard = selectedActivity.summary.distanceNoCard;

            updateDashboardValues({
                driveNumHours: summary.driveTime.hours,
                driveNumMinutes: summary.driveTime.minutes,
                workNumHours: summary.workTime.hours,
                workNumMinutes: summary.workTime.minutes,
                restNumHours: summary.restTime.hours,
                restNumMinutes: summary.restTime.minutes,
                availableNumHours: summary.availableTime.hours,
                availableNumMinutes: summary.availableTime.minutes,
                entryDurationNumHours: summary.totalDuration.hours,
                entryDurationNumMinutes: summary.totalDuration.minutes,
                driversUsed: summary.drivers,
                numDriversUsed: summary.drivers.length,
                distanceTotal: _totalDistance,
                distanceNoCard: _distanceNoCard,
                openingOdometerReading: selectedActivity.summary.openingOdo,
                closingOdometerReading: selectedActivity.summary.closingOdo,
                utilisedPercentage: selectedActivity.summary.utilisation,
            });

        } else if (selectedDates.length > 0 && calendarData) {
            const utilisation = calculateUtilisationPercentage(selectedDates, calendarData);
            const summary = calculateActivitySummary(selectedDates, calendarData);
            const { _totalDistance, _distanceNoCard } = calculateDistanceSummary(selectedDates, calendarData);

            let openingOdo = 0;
            let closingOdo = 0;

            if (selectedDates.length === 1) {
                const dayData = calendarData.assetCalendarDays.find(day =>
                    moment(day.date).isSame(selectedDates[0], 'day')
                );
                if (dayData && dayData.summary) {
                    openingOdo = dayData.summary.openingOdo;
                    closingOdo = dayData.summary.closingOdo;
                }
            } else if (selectedDates.length > 1) {
                const sortedDates = [...selectedDates].sort((a, b) => a - b);
                const firstDayData = calendarData.assetCalendarDays.find(day =>
                    moment(day.date).isSame(sortedDates[0], 'day')
                );
                const lastDayData = calendarData.assetCalendarDays.find(day =>
                    moment(day.date).isSame(sortedDates[sortedDates.length - 1], 'day')
                );

                if (firstDayData && firstDayData.summary) {
                    openingOdo = firstDayData.summary.openingOdo;
                }

                if (lastDayData && lastDayData.summary) {
                    closingOdo = lastDayData.summary.closingOdo;
                }
            }

            updateDashboardValues({
                driveNumHours: summary.driveTime.hours,
                driveNumMinutes: summary.driveTime.minutes,
                workNumHours: summary.workTime.hours,
                workNumMinutes: summary.workTime.minutes,
                restNumHours: summary.restTime.hours,
                restNumMinutes: summary.restTime.minutes,
                availableNumHours: summary.availableTime.hours,
                availableNumMinutes: summary.availableTime.minutes,
                entryDurationNumHours: summary.totalDuration.hours,
                entryDurationNumMinutes: summary.totalDuration.minutes,
                driversUsed: summary.drivers,
                numDriversUsed: summary.drivers.length,
                distanceTotal: _totalDistance,
                distanceNoCard: _distanceNoCard,
                openingOdometerReading: openingOdo,
                closingOdometerReading: closingOdo,
                utilisedPercentage: utilisation,
            });

        } else {
            resetDashboardValues();
        }
    }, [selectedActivity, selectedDates, calendarData]);


    useEffect(() => {
        if (!loading) {
            setIsWeekChanging(false);
        }
    }, [loading]);

    const assignTypes = [
        <MenuItem text="Assign driver" onClick={assignDriver} key="upload-printout" />,
        <MenuItem text="Unassign driver" onClick={unassignDriver} key="upload-printout" />
    ];

    function callActivityTypes() {
        AssetService.getActivityTypes().then((response) => {
            setActivityTypes(response);
            const actTypes = response.map((x) => {
                return { "name": x.name, "colours": x.colour, "id": x.id }
            });
            setVehicleActivitiesForKeyModal(actTypes);
        }).catch((error) => {
            NotificationToaster.show(Intent.DANGER, `Failed to get Activity Types. ${error}`);
        });
    }

    function callAssetOptions() {
        AssetService.getAssetOptions().then((response) => {
            if (response != null) {
                const assetStatuses = response.assetStatuses.map((x) => {
                    return {"name": x.name, "colours": x.colour, "id": x.id}
                });
                
                const calendarIcons = response.calendarIcons.map((x) => {
                    return {"name" : x.name, "colours": x.colour, "id": x.id, "icon": x.icon, "useIconColour" : true }
                });

                const ownershipAttributes = response.assetOwnerships.map((x) => {
                    return { "id": x.id, "name": x.name, "className": x.style, "colour": x.colour, "icon": x.icon }
                });

                const ownershipsForKey = response.assetOwnerships.map((x) => {
                    return { "name": x.name, "colours": x.colour, "id": x.id, "className": x.style, "icon": x.icon }
                });

                const statusesForKey = response.assetStatuses.map((x) => {
                    return { "name": x.name, "colours": x.colour, "id": x.id }
                });

                setStatusAttributeMappings(assetStatuses);
                setCalendarIconsForKeyModal(calendarIcons);
                setOwnershipAttributeMappings(ownershipAttributes);
                setOwnershipForKeyModal(ownershipsForKey);
                setAssetStatusesForKeyModal(statusesForKey);
            }
        }).catch((error) => {
            NotificationToaster.show(Intent.DANGER, `Failed to get Asset Options. ${error}`);
        });
    }

    function prepareUploadModalJsx(uploads) {
        let tableHeaders = ["Time of upload", "Last activity", "Download file"];
        let uploadIds = [];
        let tableRows = uploads.map((u, idx) => {
            uploadIds.push(u.VuQueueId);
            return (
                [moment(u.uploadDate).format("DD MMM YYYY HH:mm"),
                moment(u.lastActivityDate).format("DD MMM YYYY HH:mm"),
                <Tooltip content='Click to download file.' key={`listing-table-uploads-download-${u.VuQueueId}-${idx}`}><Button icon='import' minimal={true} large={false} onClick={() => OnGetFileClick(uploadIds)} disabled={uploadDownloading}></Button></Tooltip>]
            )
        });
        setUploadModalJsx(
            <>
                <Button icon='import' minimal={false} large={true} intent="primary" text="Download all files" onClick={() => OnGetFilesClick(uploadIds)} disabled={uploadDownloading || tableRows.length < 1} className="spacer-bottom"></Button>
                <ListingTable
                    id="listing-table-uploads"
                    headers={tableHeaders}
                    data={tableRows}
                    totalRecordCount={tableRows.length}
                    loadingData={loading}
                    noDataMessage={"No uploads in period"}
                    pageable={false}
                />
            </>
        )
    }

    function setUpUploadListItems() {
        setLoadingGetFile(true);
        AssetService.getUploads(id, startDate.format("YYYY-MM-DD")).then((response) => {
            setUploadsModalTitle(`Uploads containing data for period ${moment(startDate).format(periodSelectorDateFormat)} - ${moment(startDate).add(calendarDays - 1, 'days').format(periodSelectorDateFormat) }`);
            prepareUploadModalJsx(response);
            setLoadingGetFile(false);
            setShowUploadsModal(true);
        }).catch((error) => {
            NotificationToaster.show(Intent.DANGER, `Failed to get Uploads. ${error}`);
            setLoadingGetFile(false);
        });
    }

    function openKeyModal() {
        setShowKeyModal(true);
    }

    function closeKeyModal() {
        setShowKeyModal(false);
    }

    function onReportDownloading(isDownloading) {
        setReportDownloading(isDownloading);
    }

    function addNote() {
        return {};
    }

    function assignDriver() {
        return {};
    }

    function unassignDriver() {
        return {};
    }


    function convertMinutesToHoursAndMinutes(totalMinutes) {
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;
        return { hours, minutes };
    }

    function calculateDistanceSummary(_selectedDates, _calendarData) {
        let _totalDistance = 0;
        let _distanceNoCard = 0;

        _selectedDates.forEach((date) => {
            const dayData = _calendarData.assetCalendarDays.find(day =>
                moment(day.date).isSame(date, 'day')
            );

            if (dayData && dayData.summary) {
                _totalDistance += dayData.summary.distance;
                _distanceNoCard += dayData.summary.distanceNoCard;
            }
        });

        return {
            _totalDistance,
            _distanceNoCard
        };
    }

    function calculateUtilisationPercentage(_selectedDates, _calendarData) {
        let totalUtilisation = 0;

        _selectedDates.forEach((date) => {
            const dayData = _calendarData.assetCalendarDays.find(day =>
                moment(day.date).isSame(date, 'day')
            );

            if (dayData && dayData.summary && dayData.summary.utilisation !== undefined) {
                totalUtilisation += dayData.summary.utilisation;
            }
        });

        return _selectedDates.length > 1
            ? Math.round(totalUtilisation / _selectedDates.length)
            : totalUtilisation;
    }

    function calculateActivitySummary(_selectedDates, _dataRange) {
        let totalDriveMinutes = 0;
        let totalWorkMinutes = 0;
        let totalRestMinutes = 0;
        let totalAvailableMinutes = 0;
        let drivers = [];

        _selectedDates.forEach((date) => {
            const dayData = _dataRange.assetCalendarDays.find(day =>
                moment(day.date).isSame(date, 'day')
            );

            if (dayData && dayData.summary && dayData.summary.activitySummary) {
                totalDriveMinutes += dayData.summary.activitySummary.drive;
                totalWorkMinutes += dayData.summary.activitySummary.work;
                totalRestMinutes += dayData.summary.activitySummary.rest;
                totalAvailableMinutes += dayData.summary.activitySummary.poa;
            }

            if (dayData && dayData.cardIwInfo) {
                addUniqueDrivers(dayData.cardIwInfo, drivers);
            }
        });

        const driveTime = convertMinutesToHoursAndMinutes(totalDriveMinutes);
        const workTime = convertMinutesToHoursAndMinutes(totalWorkMinutes);
        const restTime = convertMinutesToHoursAndMinutes(totalRestMinutes);
        const availableTime = convertMinutesToHoursAndMinutes(totalAvailableMinutes);

        const totalDurationMinutes = totalDriveMinutes + totalWorkMinutes + totalRestMinutes + totalAvailableMinutes;
        const totalDuration = convertMinutesToHoursAndMinutes(totalDurationMinutes);

        return {
            driveTime,
            workTime,
            restTime,
            availableTime,
            totalDuration,
            drivers
        };
    }

    function addUniqueDrivers(cardIwInfo, drivers) {
        cardIwInfo.forEach((info) => {
            if (info.driver && info.driver.employeeId !== null && !drivers.find((x) => x.id === info.driver.employeeId)) {
                drivers.push(info.driver);
            }
        });
    }

    function calculateActivitySummaryForActivity(activity) {
        let totalDriveMinutes = 0;
        let totalWorkMinutes = 0;
        let totalRestMinutes = 0;
        let totalAvailableMinutes = 0;
        let drivers = [];

        if (activity.driver && activity.driver.employeeId !== null) {
            drivers.push(activity.driver);
        }

        if (activity && activity.summary && activity.summary.activitySummary) {
            totalDriveMinutes += activity.summary.activitySummary.drive;
            totalWorkMinutes += activity.summary.activitySummary.work;
            totalRestMinutes += activity.summary.activitySummary.rest;
            totalAvailableMinutes += activity.summary.activitySummary.poa;
        }

        const driveTime = convertMinutesToHoursAndMinutes(totalDriveMinutes);
        const workTime = convertMinutesToHoursAndMinutes(totalWorkMinutes);
        const restTime = convertMinutesToHoursAndMinutes(totalRestMinutes);
        const availableTime = convertMinutesToHoursAndMinutes(totalAvailableMinutes);

        const totalDurationMinutes = totalDriveMinutes + totalWorkMinutes + totalRestMinutes + totalAvailableMinutes;
        const totalDuration = convertMinutesToHoursAndMinutes(totalDurationMinutes);

        return {
            driveTime,
            workTime,
            restTime,
            availableTime,
            totalDuration,
            drivers
        };
    }

    function callUploadSummary() {
        AssetService.getUploadSummary(id).then((response) => {
            if (response != null) {
                setLastVehicleUpload(response.uploadDate);
                setLastActivityOnVehicleDownload(response.lastActivityDate);
            }
        }).catch((error) => {
            NotificationToaster.show(Intent.DANGER, `Failed to get Upload Summaries. ${error}`);
        });
    }

    function updateDashboardValues(values) {
        setDriveNumHours(values.driveNumHours);
        setDriveNumMinutes(values.driveNumMinutes);
        setWorkNumHours(values.workNumHours);
        setWorkNumMinutes(values.workNumMinutes);
        setRestNumHours(values.restNumHours);
        setRestNumMinutes(values.restNumMinutes);
        setAvailableNumHours(values.availableNumHours);
        setAvailableNumMinutes(values.availableNumMinutes);
        setEntryDurationNumHours(values.entryDurationNumHours);
        setEntryDurationNumMinutes(values.entryDurationNumMinutes);
        setDriversUsed(values.driversUsed);
        setNumDriversUsed(values.numDriversUsed);
        setDistanceTotal(values.distanceTotal);
        setDistanceNoCard(values.distanceNoCard);
        setOpeningOdometerReading(values.openingOdometerReading);
        setClosingOdometerReading(values.closingOdometerReading);
        setUtilisedPercentage(values.utilisedPercentage);
    }
    function resetDashboardValues() {
        updateDashboardValues({
            driveNumHours: 0,
            driveNumMinutes: 0,
            workNumHours: 0,
            workNumMinutes: 0,
            restNumHours: 0,
            restNumMinutes: 0,
            availableNumHours: 0,
            availableNumMinutes: 0,
            entryDurationNumHours: 0,
            entryDurationNumMinutes: 0,
            driversUsed: [],
            numDriversUsed: 0,
            distanceTotal: 0,
            distanceNoCard: 0,
            openingOdometerReading: 0,
            closingOdometerReading: 0,
            utilisedPercentage: 0,
        });
    }

    function formatDateSelection() {
        if (isWeekChanging) {
            return "";
        }

        if (selectedActivity) {
            let start = moment(selectedActivity.shiftStartDate).format('DD MMM YYYY');
            let end = moment(selectedActivity.shiftEndDate).format('DD MMM YYYY');

            if (start === end) {
                return start;
            } else {
                return `${start} - ${end}`;
            }
        } else if (selectedDates.length > 0) {
            return formatDateRanges(selectedDates);
        } else {
            return "Please select date/s to view data";
        }
    }

    function formatDateRanges(dates) {
        let ranges = [];
        let rangeStart = dates[0];
        let lastDate = dates[0];

        for (let i = 1; i < dates.length; i++) {
            let currentDate = dates[i];
            if (currentDate.diff(lastDate, 'days') === 1) {
                lastDate = currentDate;
            } else {
                ranges.push(rangeStart.isSame(lastDate) ? rangeStart.format('DD MMM YYYY')
                    : `${rangeStart.format('DD MMM YYYY')} - ${lastDate.format('DD MMM YYYY')}`);
                rangeStart = currentDate;
                lastDate = currentDate;
            }
        }

        ranges.push(rangeStart.isSame(lastDate) ? rangeStart.format('DD MMM YYYY')
            : `${rangeStart.format('DD MMM YYYY')} - ${lastDate.format('DD MMM YYYY')}`);
        return ranges.join(', ');
    }

    function asLongDate(date) {
        const d = new Date(date);
        return d.toLocaleDateString('en-GB', {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
        }).replace(',', '');
    }

    function mapDriversUsed() {
        return (
            <div className="asset-calendar-dashboard-driver-container">
                {driversUsed.map((driver) => (
                    <a
                        key={driver.id}
                        href={LinkService.getDriverProfileUrl(driver.employeeId)}
                        className="asset-calendar-dashboard-person-icon"
                    >
                        <i className="fa fa-user"></i>
                        <span>{driver.driverFullName}</span>
                    </a>
                ))}
            </div>
        );
    }

    function toggleDisplayVehicleActivity() {
        setShowVehicleActivity(false);
        setShowDriversUsed(true);
        setShowUploads(true);
    }
    function toggleDisplayUploads() {
        setShowUploads(false);
        setShowVehicleActivity(true);
        setShowDriversUsed(true);
    }

    function toggleDisplayDriversUsed() {
        setShowDriversUsed(false);
        setShowVehicleActivity(true);
        setShowUploads(true);
    }

    function onWeekChange(periodStart) {
        if (periodStart.toString() !== startDate.format("DD MMM YYYY")) {
            resetDashboardValues();
            setSelectedDates([]);
            setLoading(true);
            let newDt = moment(periodStart, periodSelectorDateFormat);
            dispatch(SetFiltering(fleetDateReduxName, { startDate: newDt }));
            setStartDate(newDt);
            setIsWeekChanging(true);
        }
    }

    function setWeekCheckedChanged(weekNumber) {
        setCheckedWeeks(() => {
            return checkedWeeks.map((item) => {
                item.checked = item.index === weekNumber ? !item.checked : item.checked;
                return item;
            });
        });
    }

    function handleWeekSelectedChange(weekNumber, shouldBeChecked) {
        let startWeekIndex = weekNumber * daysInWeek;
        setWeekCheckedChanged(weekNumber);
        const weekDays = calendarData.assetCalendarDays.slice(startWeekIndex, startWeekIndex + daysInWeek);

        setCalendarData((prev) => {
            let clone = { ...prev };
            clone.assetCalendarDays.filter(d => d.index >= startWeekIndex && d.index < startWeekIndex + daysInWeek).map((item) => {
                item.selected = shouldBeChecked;
                return item;
            });
            return clone;
        });
        setSelectedActivity(null);
        setSelectedDates((prevSelectedDates) => {
            let newDates = [...prevSelectedDates];
            if (shouldBeChecked) {
                weekDays.forEach(day => {
                    let date = moment(day.date);
                    addDateIfNotPresent(newDates, date);
                });
            } else {
                resetDashboardValues();
                newDates = filterOutWeekDays(newDates, weekDays);
            }
            return newDates.sort((a, b) => a - b);
        });
    }

    function addDateIfNotPresent(datesArray, dateToAdd) {
        if (!datesArray.some(d => d.isSame(dateToAdd))) {
            datesArray.push(dateToAdd);
        }
    }

    function filterOutWeekDays(datesArray, weekDays) {
        return datesArray.filter(date => !weekDays.some(day => moment(day.date).isSame(date)));
    }

    function handleDaySelectedChange(event, dayNumber) {
        let selectedDay = calendarData.assetCalendarDays[dayNumber];
        let date = moment(selectedDay.date);
        setSelectedActivity(null);
        if (event.ctrlKey) {
            resetDashboardValues();
            setCalendarData((prev) => {
                let clone = { ...prev };
                clone.assetCalendarDays.filter(d => d.index === dayNumber).map((item) => {
                    item.selected = !item.selected;
                    return item;
                });
                return clone;
            });

            setSelectedDates((prevSelectedDates) => {
                let newDates = [...prevSelectedDates];
                if (newDates.some(d => d.isSame(date))) {
                    newDates = newDates.filter(d => !d.isSame(date));
                } else {
                    newDates.push(date);
                }

                return newDates.sort((a, b) => a - b);
            });
        } else {
            setCalendarData((prev) => {
                let clone = { ...prev };
                clone.assetCalendarDays.map((item) => {
                    item.selected = item.index === dayNumber ? !item.selected : false;
                    return item;
                });
                return clone;
            });


            setSelectedDates((prevSelectedDates) => {
                if (prevSelectedDates.some(d => d.isSame(date))) {
                    resetDashboardValues();
                    return [];
                } else {
                    return [date];
                }
            });
        }

        if (event.detail > 1 && ownershipForKeyModal && ownershipForKeyModal.length > 0) {
            var day = calendarData.assetCalendarDays.find(d => d.index === dayNumber);
            displayDayDetailModal(day.date);
        }
    }

    function getActivityPositions(_selectedActivity, _allActivities) {
        let _isFirstActivity = false;
        let _isLastActivity = false;

        if (_selectedActivity && _allActivities.length > 0) {
            const currentActivityKey = `${_selectedActivity.shiftStartDate}-${_selectedActivity.shiftEndDate}`;
            const currentIndex = _allActivities.findIndex(activity => {
                const activityKey = `${activity.shiftStartDate}-${activity.shiftEndDate}`;
                return activityKey === currentActivityKey;
            });
            _isFirstActivity = currentIndex === 0;
            _isLastActivity = currentIndex === _allActivities.length - 1
                || (currentIndex === _allActivities.length - 2 && _allActivities[_allActivities.length - 1].shiftStartDate === _allActivities[_allActivities.length - 2].shiftStartDate);
        }

        return { _isFirstActivity, _isLastActivity };
    }

    function handleActivitySelectedChange(event, activity) {
        event.stopPropagation(); // Prevent day selection
        setSelectedDates([]);

        setCalendarData((prev) => {
            let clone = { ...prev };
            clone.assetCalendarDays = clone.assetCalendarDays.map((item) => {
                item.selected = false;
                return item;
            });
            return clone;
        });

        setSelectedActivity((prevActivity) => {
            const currentActivityKey = `${activity.shiftStartDate}-${activity.shiftEndDate}`;
            const previousActivityKey = prevActivity
                ? `${prevActivity.shiftStartDate}-${prevActivity.shiftEndDate}`
                : null;

            if (prevActivity && previousActivityKey === currentActivityKey) {
                return null;
            }
            return activity;
        });
    }

    function handlePrevActivity() {
        if (selectedActivity && allActivities.length > 0) {
            const currentActivityKey = `${selectedActivity.shiftStartDate}-${selectedActivity.shiftEndDate}`;
            const currentIndex = allActivities.findIndex(activity => {
                const activityKey = `${activity.shiftStartDate}-${activity.shiftEndDate}`;
                return activityKey === currentActivityKey;
            });
            if (currentIndex > 0) {
                const prevActivity = allActivities[currentIndex - 1];
                setSelectedActivity(prevActivity);
            }
        }
    }

    function handleNextActivity() {
        if (selectedActivity && allActivities.length > 0) {
            const currentActivityKey = `${selectedActivity.shiftStartDate}-${selectedActivity.shiftEndDate}`;
            const currentIndex = allActivities.findIndex(activity => {
                const activityKey = `${activity.shiftStartDate}-${activity.shiftEndDate}`;
                return activityKey === currentActivityKey;
            });
            if (currentIndex < allActivities.length - 1) {
                
                if (selectedActivity.shiftStartDate === allActivities[currentIndex + 1].shiftStartDate
                    && currentIndex < allActivities.length - 2) {
                    const nextActivity = allActivities[currentIndex + 2]; //multi day
                    setSelectedActivity(nextActivity);
                }
                else {
                    const nextActivity = allActivities[currentIndex + 1];
                    setSelectedActivity(nextActivity);
                }
            }
        }
    }

    function sanitiseWeeksSelected(){
        for (let i = 0; i < tachoWeeksToShow; i++) {
            let isWeekChecked = checkedWeeks[i].checked;
            let startWeekIndex = i * daysInWeek;
            let selectedInWeek = calendarData.assetCalendarDays.filter(d => { return d.selected && d.index >= startWeekIndex && d.index < startWeekIndex + daysInWeek });
            let weekAllSelected = selectedInWeek.length === daysInWeek;
            if (isWeekChecked !== weekAllSelected) {
                setWeekCheckedChanged(i);
            }
        }
    }

    function mapAssetCalendar() {
        if (loading || calendarData?.assetCalendarDays == null || calendarData.assetCalendarDays.length < tachoWeeksToShow + 1 * daysInWeek ) {
            setAssetCalendarJsx(<div className="bp3-skeleton calendar-loading"></div>);
            return;
        }

        let calendarMonth = [];
        for (let i = 0; i < tachoWeeksToShow; i++) {
            calendarMonth.push(mapAssetCalendarWeekJsx(i));
        }

        setAssetCalendarJsx(<div className="calendar-month">
            {calendarMonth.map(input => { return input })}
        </div>);
        sanitiseWeeksSelected();
    }

    function mapAssetCalendarWeekJsx(weekNumber) {
        let calendarWeek = [];
        for (let i = 0; i < daysInWeek; i++) {
            let day = calendarData.assetCalendarDays[i + (weekNumber * daysInWeek)];
            let cellIcons = setUpIcons(day);
            calendarWeek.push(mapAssetCalendarDayJsx(day, weekNumber % 2 === 0, cellIcons));
        }
        return (<div className="calendar-week" key={`calendar-week-${weekNumber}`} >
            {calendarWeek.map(input => { return input })}
            <div className="calendar-cell-select-week">
                <FormCheckbox
                    checked={checkedWeeks[weekNumber].checked}
                    label=''
                    id={`week-checkbox-${weekNumber}`}
                    key={`week-checkbox-${weekNumber}`}
                    onChange={() => handleWeekSelectedChange(weekNumber, !checkedWeeks[weekNumber].checked)}
                    loading={loading}
                    name={`week-checkbox-${weekNumber}`}
                />
            </div>
        </div>);
    }

    function OnGetFilesClick(uploadIds) {
        uploadIds.forEach((uploadId) => { OnGetFileClick(uploadId) });
    }

    function OnGetFileClick(uploadId) {
        setUploadingDownloading(true);
        AssetService.getDownloadLinkForVehicleUnitFile(uploadId).then((response) => {
            BlobStorageService.downloadFile(response.storageName, response.containerName, response.fileName, response.token, response.downloadName);
            setUploadingDownloading(false);
        }, (error) => {
            setUploadingDownloading(false);
            NotificationToaster.show(Intent.DANGER, `Failed to get File. ${error}`, false);
        });
    }

    function getIconInfo(date, iconName, evaluator, tooltipContent, dayDetailModalTab, clickEvent) {
        let calIcon = calendarIconsForKeyModal.find((x) => x.name === iconName);
        let clkEvent = clickEvent === null ? () => { displayDayDetailModal(date, dayDetailModalTab) } : clickEvent;
        evaluator = evaluator || false;
        return {
            clickEvent: clkEvent,
            evaluator: evaluator,
            icon: calIcon.icon,
            tooltipContent: tooltipContent,
            key: `asset-${calIcon.icon}-icon`,
            colour: calIcon.colours
        };
    }

    function setUpIcons(dayData) {
        let commentsTooltipJsx = dayData.activityInfo.filter(a => { return a.comment && a.comment.length > 0 }).map((c, idx) => {
            return (<span className="calendar-cell-tooltip" key={`calendar-cell-comments-tooltip-${dayData.index}-${idx}`}>{c.comment.substring(0, maxCommentLength)}</span>)
        });
        let uploadsTooltipJsx = dayData.uploads.map((u, idx) => {
            return (<span className="calendar-cell-tooltip" key={`calendar-cell-upload-tooltip-${dayData.index}--${idx}`}>
                {`Upload time: ${moment(u.uploadDate).format("HH:mm")}`}
            </span>)
        });

        return [getIconInfo(dayData.date, "Files uploaded", dayData.uploads && dayData.uploads.length > 0, uploadsTooltipJsx, null, () => { openUploadsModalForDay(dayData.index) }),
            getIconInfo(dayData.date, "Notes", dayData.note && dayData.note.length > 0, dayData.note, "NOTES", null),
            getIconInfo(dayData.date, "Comment", commentsTooltipJsx && commentsTooltipJsx.length > 0, commentsTooltipJsx, "ACTIVITIES", null),
            getIconInfo(dayData.date, "Speed data", dayData.HasSpeedData, "Speed data", "SPEED_DATA", null),
            getIconInfo(dayData.date, "Harsh braking", dayData.HarshBrakingCount !== null && dayData.HarshBrakingCount > 0, `Harsh braking occurrences: ${dayData.HarshBrakingCount}`, "SPEED_DATA", null)]
    }

    function mapAssetCalendarDayJsx(dayData, weekOdd, cellIcons) {
        return (
            <CalendarCell
                dayIndex={dayData.index}
                data={dayData}
                loading={loading}
                weekOdd={weekOdd}
                handleDaySelectedChange={handleDaySelectedChange}
                handleActivitySelectedChange={handleActivitySelectedChange}
                isSelected={dayData.selected}
                selectedActivity={selectedActivity}
                entityId={id}
                showTimes={showTimes}
                key={`calendar-cell-${dayData.index}`}
                cellIcons={cellIcons}
            />
        );
    }

    function openPreferencesModal() {
        setShowPreferencesModal(true);
    }

    function closePreferencesModal() {
        setShowPreferencesModal(false);
    }

    function openUploadsModalForDay(dayId) {
        setUploadsModalDayId(dayId)
        setShowUploadsModal(true);
    }

    function closeUploadsModal() {
        setUploadsModalDayId(null);
        setUploadsModalTitle("");
        setShowUploadsModal(false);
    }

    function onShowTimesChange(item) {
        setShowTimes((prev) => {
            return !prev;
        });
    }

    function mapAssetCalendarDay(dayData, index) {
        dayData.index = index;
        dayData.selected = false;

        dayData.activityInfo = dayData.cardIwInfo.map((c) => {
            let dayStart = moment(dayData.date);
            let cardStart = moment(c.dayStartTimeBst);
            let minutesFromStart = cardStart.diff(dayStart, 'minutes');
            let dayDuration = moment(c.dayEndTimeBst).diff(cardStart, 'minutes');
            let percentageFromStart = ((minutesFromStart / minutesInDay) * 100) + '%';
            let activityType = activityTypes.find((x) => x.id === c.activityTypeId);

            return {
                ...c,
                selected: false,
                leftPercent: percentageFromStart,
                durationPercentage: (dayDuration / minutesInDay * 100) + '%',
                activityType: activityType
            };
        });

        return dayData;
    }

    function mapAssetCalendarDays(daysData) {
        let _allActivities = [];

        const mappedDays = daysData.map((dayData, index) => {
            const mappedDay = mapAssetCalendarDay(dayData, index);
            if (mappedDay.activityInfo) {
                _allActivities = _allActivities.concat(mappedDay.activityInfo);
            }
            return mappedDay;
        });

        _allActivities.sort((a, b) => moment(a.shiftStartDate).diff(moment(b.shiftStartDate)));
        setAllActivities(_allActivities);

        return mappedDays;
    }

    function displayDayDetailModal(date, openModal) {
        setDayDetailOpenTab(openModal);
        setDayDetailModalAssetId(id);
        setDayDetailModalDate(date);
        setShowDayDetailModal(true);
    }

    useEffect(() => {
        //We know the redux store has loaded, we only want to set the offset once
        if (!reduxLoaded) {
            setReduxLoaded(true);
            if (reduxDate && reduxDate.startDate) {
                setInitialPeriodOffset(reduxDate.startDate.diff(initialDate, 'weeks'));
            } else {
                setInitialPeriodOffset(0);
            }
        }
    }, [reduxDate]);

    useEffect(() => {
        if (checkedWeeks.length > 0 && calendarData && calendarData.assetCalendarDays && calendarIconsForKeyModal && calendarIconsForKeyModal.length > 0) {
            if (loading) {
                handleWeekSelectedChange(2, true);
                setLoading(false);
                return;
            } else {
                mapAssetCalendar();
            }
        }
    }, [calendarData, checkedWeeks, showTimes, calendarIconsForKeyModal, selectedActivity]);

    useEffect(() => {
        dispatch({ type: 'SITE_FULL_WIDTH' });
        let weeks = [];
        for (let i = 0; i < tachoWeeksToShow; i++) {
            weeks.push({ index: i, checked: false });
        };
        setCheckedWeeks(weeks);
        callActivityTypes();
        callAssetOptions();
        callUploadSummary();
    }, []);

    useEffect(() => {
        AssetService.getTachoDataAssetCalendar(id, startDate.format("YYYY-MM-DD"), calendarDays).then((response) => {
            setLoadingGetFile(false);
            response.assetCalendarDays = mapAssetCalendarDays(response.assetCalendarDays);
            let assetInfo = response.assetRegistration;
            if (response.fleetNumber !== null && response.fleetNumber.length > 0) {
                assetInfo = assetInfo + ` / ${response.fleetNumber}`;
            }
            response = {
                ...response,
                assetInfo: assetInfo
            };
            setCalendarData(response);
            setInitialising(false);
        }).catch((error) => {
            NotificationToaster.show(Intent.DANGER, `Failed to obtain calendar data. Please refresh page and try again.`);
        });
    }, [startDate]);

    useEffect(() => {
        if (uploadsModalDayId !== null) {
            let day = calendarData.assetCalendarDays.find(d => d.index === uploadsModalDayId);
            setUploadsModalTitle(`Uploads on ${moment(day.date).format(periodSelectorDateFormat)}`);
            prepareUploadModalJsx(day.uploads);
        }
    }, [uploadsModalDayId]);

    return (
        <UserAccess perform={requiredActions}
            yes={() => (
                <div className="row">
                    <Breadcrumbs items={props.breadcrumbs} />

                    <div className={classNames({ "bp3-skeleton": initialising }, 'inline-items', 'spacer-bottom', 'nowrap')}>
                        <FormHeading headingLevel="h2">Asset Calendar </FormHeading>
                        <span className='inline-items no-spacing'>
                            <AssetStatus statusId={calendarData?.assetStatus.id} ownershipId={calendarData?.assetOwnership.id} isLoading={loading} statusAttributeMappings={statusAttributeMappings} ownershipAttributeMappings={ownershipAttributeMappings} label={calendarData?.assetInfo} />
                            <FormHeading headingLevel="h2" isLoading={loading}><a href={`${window.env.VISION_URL}/#/Fleet/RedirectToProfile?assetId=${id}`}>{calendarData?.assetInfo}</a></FormHeading>
                        </span>
                    </div>

                    <div className="button-row button-row-stacked-mobile spacer-bottom">
                        <Button intent="primary" text="Get File" id="get-file-dropdown" onClick={setUpUploadListItems} disabled={loading || loadingGetFile} />
                        <Button intent="primary" text="Add Note" id="add-note" onClick={addNote} />
                        <Dropdown intent="primary" text="Assign" id="assign" items={assignTypes} />
                        <ReportList lastSearchPayload={lastSearchPayload} onReportDownloadingChange={onReportDownloading} pageName='Fleet' />
                        <Button intent="secondary" text="Key" id="key" onClick={openKeyModal} className="key-button" />
                    </div>
                    <NotificationInline intent="success" text="Your report has been queued, and will download as soon as it's ready." show={reportDownloading} />

                    <div className={classNames({ "bp3-skeleton": initialising }, 'inline-items', 'pull-left', 'spacer-bottom-medium')}>
                        <FormDatePeriodSelector
                            id="listing-period"
                            periodType="weeks"
                            periodsToShow={tachoWeeksToShow}
                            startDate={initialDate.toString()}
                            onChange={onWeekChange}
                            dateFormat={periodSelectorDateFormat}
                            showCalendar={true}
                            showText={false}
                            initialPeriodOffset={initialPeriodOffset}
                        />
                        <div className="form-field">
                            <Button text="Configure Preferences" minimal={true} large={false} intent="secondary" icon="cog" id="configure-properties" className="configure-prefs-button" onClick={openPreferencesModal} />
                        </div>
                    </div>

                    <div className="clear-divide"></div>

                    <div className={classNames("calendar-week", "spacer-bottom")} >

                        {daysOfWeek.map(function (dayName, index) {
                            return (
                                <div className="day-of-week-header" key={dayName}></div>
                            );
                        })}

                        {assetCalendarJsx}
                    </div>

                    <Modal
                        isOpen={showPreferencesModal}
                        title="Calendar Preferences"
                        isCloseButtonShown={false}
                        onClose={closePreferencesModal}
                        autoFocus={false}
                        enforceFocus={false}
                        canOutsideClickClose={false}
                        wide={true}
                        updateStateOnClose={false}
                    >
                        <div className="preferences-modal-body form-field form-field-select">
                            <h2>Times</h2>
                            <FormCheckbox
                                checked={showTimes}
                                label='Show times on calendar'
                                onChange={onShowTimesChange}
                                loading={loading}
                            />
                        </div>
                    </Modal>

                    <Modal
                        isOpen={showUploadsModal}
                        title={uploadsModalTitle}
                        isCloseButtonShown={false}
                        onClose={closeUploadsModal}
                        autoFocus={false}
                        enforceFocus={false}
                        canOutsideClickClose={false}
                        wide={true}
                        updateStateOnClose={false}
                    >
                        <div className="form-field form-field-select">
                            {uploadModalJsx}
                        </div>
                    </Modal>

                    <DayDetailModal
                        showDayDetailModal={showDayDetailModal}
                        closeDayDetailModal={() => setShowDayDetailModal(false)}
                        assetId={dayDetailModalAssetId}
                        date={dayDetailModalDate.toString()}
                        statusAttributeMappings={assetStatusesForKeyModal}
                        ownershipAttributeMappings={ownershipForKeyModal}
                        lastSearchPayload={lastSearchPayload}
                        vehicleActivityTypes={vehicleActivitiesForKeyModal}
                        openTabName={dayDetailOpenTab}
                    />

                    <CalendarKeyModal
                        showKeyModal={showKeyModal}
                        closeKeyModal={closeKeyModal}
                        isInitialising={initialising}
                        activityTypesForKeyModal={vehicleActivitiesForKeyModal}
                        calendarIconsForKeyModal={calendarIconsForKeyModal}
                    />
                    <Card className="asset-calendar-dashboard">
                        <div className="asset-calendar-dashboard-header-container">
                            <div>
                                <div className="asset-calendar-dashboard-header">SELECTED DATE/S</div>
                                <div className="asset-calendar-dashboard-date">
                                    {formatDateSelection()}
                                </div>
                            </div>
                            <div className="asset-calendar-dashboard-navigation-container">
                                {selectedActivity ? (
                                    <>
                                        <div className="asset-calendar-dashboard-header">SELECTED ENTRY</div>
                                        <div className="asset-calendar-dashboard-navigation-buttons">
                                            <Button
                                                large={false}
                                                minimal={true}
                                                icon="fast-backward"
                                                onClick={handlePrevActivity}
                                                disabled={isFirstActivity}
                                            />
                                            <Button
                                                large={false}
                                                minimal={true}
                                                icon="fast-forward"
                                                onClick={handleNextActivity}
                                                disabled={isLastActivity}
                                            />
                                        </div>
                                    </>
                                ) : (
                                    <div ></div>
                                )}
                            </div>
                        </div>
                        <Card className="asset-calendar-dashboard-inner-card">
                            <ShowHide
                                evaluator={showVehicleActivity}
                                hide={
                                    <table className="asset-calendar-dashboard-table">
                                        <tr className="asset-calendar-dashboard-header">
                                            <th>ENTRY DURATION</th>
                                            <th>
                                                <img src={`/icons/activity-types/driving.png`} alt="driving" className="vehicle-icon" /> DRIVE
                                            </th>
                                            <th>
                                                <img src={`/icons/activity-types/work.png`} alt="work" className="vehicle-icon" /> WORK
                                            </th>
                                            <th>
                                                <img src={`/icons/activity-types/break.png`} alt="break" className="vehicle-icon" /> REST
                                            </th>
                                            <th>
                                                <img src={`/icons/activity-types/available.png`} alt="available" className="vehicle-icon" /> AVAILABLE
                                            </th>
                                            <th>UTILISED</th>
                                            <th>DISTANCE TOTAL</th>
                                            <th>DISTANCE NO CARD</th>
                                            <th>ODOMETER</th>
                                        </tr>
                                        <tr className="asset-calendar-dashboard-table-row">
                                            <td>{entryDurationNumHours}h {entryDurationNumMinutes}m</td>
                                            <td>{driveNumHours}h {driveNumMinutes}m</td>
                                            <td>{workNumHours}h {workNumMinutes}m</td>
                                            <td>{restNumHours}h {restNumMinutes}m</td>
                                            <td>{availableNumHours}h {availableNumMinutes}m</td>
                                            <td>{utilisedPercentage}%</td>
                                            <td>{distanceTotal} Km</td>
                                            <td>{distanceNoCard} Km</td>
                                            <td>
                                                {openingOdometerReading !== 0 ? `${openingOdometerReading}` : "0 Km"}
                                                {(selectedActivity || selectedDates.length > 0) && closingOdometerReading !== 0 ? ` - ${closingOdometerReading} Km` : ""}
                                            </td>
                                        </tr>
                                    </table>
                                }
                            >
                            </ShowHide>
                            <ShowHide
                                evaluator={showUploads}
                                hide={
                                    <table className="asset-calendar-dashboard-upload-table">
                                        <tr className="asset-calendar-dashboard-header">
                                            <th>LAST VU UPLOADED TO FTA</th>
                                            <th>LAST ACTIVITY ON VU DOWNLOAD</th>
                                        </tr>
                                        <tr className="asset-calendar-dashboard-table-row">
                                            <td>{asLongDate(lastVehicleUpload)}</td>
                                            <td>{asLongDate(lastActivityOnVehicleDownload)}</td>
                                        </tr>
                                    </table>
                                }
                            >
                            </ShowHide>
                            <ShowHide
                                evaluator={showDriversUsed}
                                hide={mapDriversUsed()}
                            >
                            </ShowHide>
                        </Card>
                        <div className="button-row">
                            <Button
                                className={classNames("asset-calendar-dashboard-button", { "selected": !showVehicleActivity })}
                                text="Vehicle Activity"
                                onClick={() => toggleDisplayVehicleActivity()}
                                noTransform={true}
                            />
                            <Button
                                className={classNames("asset-calendar-dashboard-button", { "selected": !showUploads })}
                                text="Uploads"
                                onClick={() => toggleDisplayUploads()}
                                noTransform={true}
                            />
                            <Button
                                className={classNames("asset-calendar-dashboard-button", { "selected": !showDriversUsed })}
                                text={`Drivers Used (${numDriversUsed})`}
                                onClick={() => toggleDisplayDriversUsed()}
                                noTransform={true}
                            />
                        </div>
                    </Card>		
                </div>

            )}
            no={() => <PageRestricted />}
        />
    );
}
import React, {useEffect, useState} from "react";
import {Panel} from 'components/panel';
import PropTypes from "prop-types";
import { Dropdown } from "components/dropdown";
import './InfoPanel.css';
import moment from "moment";
import { Icon, Intent, MenuItem } from "@blueprintjs/core";
import classNames from 'classnames';
import { Comment } from "components/comments";
import { AssetService } from "services";
import { NotificationToaster } from "components/notifications";
import { DateToLocal } from "components/dates/DateToLocal";

export function InfoPanel(props) {

    const [showCommentComponent, setShowCommentComponent] = useState(false);
    const [allBurgerItems, setAllBurgerItems] = useState([<MenuItem text="Initial State" key={`${props.cardIwId}-mi-initial`} />]);

    function formatTime(intTime){
        if (intTime === undefined || intTime === null)
            return "";
       return `${Math.floor(intTime / 60)}h ${intTime % 60}m`;
    }
    function HandleSaveComment(commentToSave){
        AssetService.upsertComment(props.cardIwId, commentToSave)
        .then(() => {         
            props.onCommentChange();
            NotificationToaster.show(Intent.SUCCESS, `Your comment has been successfully updated`, false);
        })
        .catch((error) => {
            NotificationToaster.show(Intent.DANGER, error, false);
        });
    }
    
    function HandleDeleteComment(){
        AssetService.deleteComment(props.cardIwId)
        .then(() => {
            props.onCommentChange();
            NotificationToaster.show(Intent.SUCCESS, `Your comment has been successfully deleted`, false);
            setShowCommentComponent(false);
        })
        .catch((error) => {
            NotificationToaster.show(Intent.DANGER, error, false);
        });

    }

    useEffect(() => {
        let items = props.burgerItems ? [...props.burgerItems] : [];
        if (props.allowAddComment || props.initialComment) {
            items.push(<MenuItem text="Add Comment" key={`${props.cardIwId}-mi-comment`} onClick={() => setShowCommentComponent(true)} />);
        }
        setShowCommentComponent(props.initialComment);
        setAllBurgerItems(items);
    }, [props.burgerItems, props.allowAddComment, props.initialComment]);

    return (
        <Panel className={classNames("info-panel", props.panelClassNames)} color={props.leftColor} disabled={props.disabled} >
            <h3 className="info-panel-header">{props.headingText}</h3>
            {
                props.showBurger && allBurgerItems.length > 0 &&
                    <div className="info-panel-button">
                        <Dropdown icon="menu" iconOnly={true} showArrow={false} minimal={true} large={false} items={allBurgerItems} popoverUsePortal={false} popoverPosition="BOTTOM_RIGHT" />
                    </div>
            }
            <div className="info-panel-content">
                <h4>{props.isSummary ? 
                    `${moment(props.startDate).format("DD MMM YYYY")}` :
                    <><DateToLocal format="DD MMM YYYY HH:mm">{props.startDate}</DateToLocal> - <DateToLocal format="DD MMM YYYY HH:mm">{props.endDate}</DateToLocal></>}
                    {props.driverName ? ` (${String(props.driverName).toLocaleLowerCase()})`: ""}
                </h4>
                <table className="info-panel-table">
                    <tbody>
                        <tr>
                            <th><Icon icon={<img src="/icons/activity-types/driving.png" className="vu-activity-icon-small" alt="Drive" />} iconSize={10} />Drive</th>
                            <th><Icon icon={<img src="/icons/activity-types/work.png" className="vu-activity-icon-small" alt="Work" />} iconSize={10} />Work</th>
                            <th><Icon icon={<img src="/icons/activity-types/break.png" className="vu-activity-icon-small" alt="Rest" />} iconSize={10} />Rest</th>
                            <th><Icon icon={<img src="/icons/activity-types/available.png" className="vu-activity-icon-small" alt="Available" />} iconSize={10} />Available</th>
                        </tr>
                        <tr>
                            <td>{formatTime(props.drive)}</td>
                            <td>{formatTime(props.work)}</td>
                            <td>{formatTime(props.rest)}</td>
                            <td>{formatTime(props.poa)}</td>
                        </tr>
                        {props.startOdo && props.endOdo && 
                        <>
                            <tr>
                                <th colSpan={4}>Odometer</th>
                            </tr>
                            <tr>
                                <td colSpan={4}>{String(props.startOdo ?? "").padStart(6, '0')} - {String(props.endOdo ?? "").padStart(6, '0')} Km</td>
                            </tr>
                        </>
                        }
                    </tbody>
                </table>
                {showCommentComponent &&
                    <Comment onSave={HandleSaveComment} onDelete={HandleDeleteComment} value={props.initialComment} id={`${props.cardIwId}-comment-panel`} isEditibleInitially={!props.initialComment} />
                }
            </div>
            {props.children}
        </Panel>
    )
}

InfoPanel.defaultProps = {
    showBurger: false,
    isSummary: false,
    disabled: false,
    cardIwId: null,
    onCommentChange: () => { },
    allowAddComment: false
};

InfoPanel.propTypes = {
    showBurger: PropTypes.bool,
    cardIwId: PropTypes.number,
    burgerItems: PropTypes.array,
    headingText: PropTypes.string.isRequired,
    leftColor: PropTypes.string,
    isSummary: PropTypes.bool,
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string,
    driverName: PropTypes.string,
    drive: PropTypes.number,
    work: PropTypes.number,
    rest: PropTypes.number,
    poa: PropTypes.number,
    startOdo: PropTypes.number,
    endOdo: PropTypes.number,
    disabled: PropTypes.bool,
    panelClassNames: PropTypes.string,
    initialComment: PropTypes.string,
    onCommentChange: PropTypes.func,
    allowAddComment: PropTypes.bool
};
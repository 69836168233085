import moment from "moment";

var _ = require('underscore');

function isNullOrUndefined(obj) {
	return obj === null || typeof (obj) === 'undefined';
}

function deepClone(object) {
	var clone = _.clone(object);
	_.each(clone, function (value, key) {
		if (_.isObject(value)) {
			clone[key] = deepClone(value);
		}
	});

	return clone;
}

function executeIfNotNull(...args) {
	var method = args.shift();
	if (typeof method == 'function') {
		method(...args);
	}
}

function insertIntoArray(currentArray, insertIndex, item) {
	return [
		...currentArray.slice(0, insertIndex),
		item,
		...currentArray.slice(insertIndex)
	];
}

function sortAlphabetically(arr, sortProperty) {
	return _.sortBy(arr, function (item) {
		return item[sortProperty].toLowerCase();
	});
}

function sortDesc(arr, sortProperty) {
	return arr.sort(function (a, b) { return b[sortProperty] - a[sortProperty] });
}

function kebabCase(words) {
	return words.toLowerCase().trim().replace(/ /g, "-").replace(/_/g, "-");
}

function downloadFile(blob, filename) {
	let href = window.URL.createObjectURL(blob);
	let link = document.createElement('a');
	link.href = href;
	link.setAttribute('download', filename); //or any other extension
	document.body.appendChild(link);
	link.click();
}

function findRecursiveItem(structure, itemId, type) {
	var response = null;
	if (structure != null) {
		for (var i = 0; i < structure.length; i++) {
			var currentItem = structure[i];
			if (currentItem.type === type && currentItem.id === itemId) {
				return currentItem;
			}
			if (currentItem.type === "group") {
				var innerResponse = findRecursiveItem(currentItem.items, itemId, type);
				if (innerResponse != null) {
					response = innerResponse;
				}
			}
		}
	}
	return response;
}

function formatDate(date, format) {
	return moment(date).format(format);
}

export const HelperFunctions = {
	isNullOrUndefined: isNullOrUndefined,
	executeIfNotNull: executeIfNotNull,
	deepClone: deepClone,
	insertIntoArray: insertIntoArray,
	sortAlphabetically: sortAlphabetically,
	sortDesc: sortDesc,
	kebabCase: kebabCase,
	downloadFile: downloadFile,
	findRecursiveItem: findRecursiveItem,
	formatDate: formatDate
};


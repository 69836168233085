import { Intent } from '@blueprintjs/core';
import { ButtonSave, Button } from 'components/buttons';
import { Link } from "react-router-dom";
import { FormTextInput, FormTextArea, FormDateInput, FormFileInput } from 'components/form-fields';
import { AlertUnsavedData, AlertConfirm, NotificationToaster } from 'components/notifications';
import { useDirtyData } from 'hooks/useDirtyData';
import React, { useEffect, useState, Fragment } from 'react';
import { BlobStorageService, JobService } from 'services';
import { UserAccess, UserHasAccess } from 'components/user-access/UserAccess';
import axios from 'axios';
import { useValidation } from "hooks/useValidation";
import { ShowHide } from "components/layout";
import { PageRestricted } from 'pages/errors/page-restricted';
import { HelperFunctions } from 'helpers';
import { ListingTable } from "components/listing";
import { DateToLocal } from "components/dates";
import { Tabs } from "components/navigation";

import moment from "moment";

export function CloseJob(props) {
    const closeJobModel = {
        jobVisitCompletionId: 0,
        employeePosition: null,
        employeeName: null,
        employeeEmail: null,
        employeePhoneNumber: null,
        comments: null,
        closedDate: moment().format("YYYY-MM-DD"),
    };

    const [isValid, errors, validate] = useValidation();
    const [fileValid, fileErrors, validateFile] = useValidation(); 
    const [closeJob, setCloseJob] = useState(closeJobModel);
    const [files, setFiles] = useState([]);
    const [fileName, setFileName] = useState("");
    const [originalCloseJob, setOriginalCloseJob] = useState(closeJobModel);
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const isDataDirty = useDirtyData(originalCloseJob, closeJob);
    const fileTypes = ['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/pdf', 'image/png', 'image/jpeg', 'text/csv', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
    const requiredAction = ["Internal"];

    const maxFileSize = 10485760;
    const maxNoFiles = 20;

    const isInternalJobEditor = UserHasAccess("Internal:Jobeditor");

    const [tableHeaders] = useState(["File Name", "File Date"]);
    const [tableRows, setTableRows] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [rawTableData, setRawTableData] = useState([]);
    const [currentTabKey, setCurrentTabKey] = useState("DETAILS");
    const [tabs, setTabs] = useState([]);
    const [jobDetails, setJobDetails] = useState(null);
    const [downloadingFile, setDownloadingFile] = useState(false);
    const [uploadedFile, setUploadedFile] = useState(null);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [selectedFile, setselectedFile] = useState(0);
    
    useEffect(initialLoad, [props.job, props.id]);

    function initialLoad() {

        if (props.job === null || props.id === null) {
            return;
        }

        setTabs([{ key: "DETAILS", title: "Closing Details", selected: true },
        { key: "FILES", title: "Documents", selected: false }]);

        setJobDetails(props.job);

        axios.all([JobService.getCompletedVisit(props.id), JobService.getJobFiles(props.id)]).then(axios.spread(function (completedVisitResponse, jobFiles) {

            var model = {
                jobVisitCompletionId: completedVisitResponse.jobVisitCompletionId,
                employeePosition: completedVisitResponse.employeePosition,
                employeeName: completedVisitResponse.employeeName,
                employeeEmail: completedVisitResponse.employeeEmail,
                employeePhoneNumber: completedVisitResponse.employeePhoneNumber,
                comments: completedVisitResponse.comments,
                closedDate: props.job.completedDate === null ? moment().format("YYYY-MM-DD") : props.job.completedDate
            };

            setCloseJob(model);
            setFiles(jobFiles);
            setOriginalCloseJob(model);
            setLoading(false);
        }), () => {
            NotificationToaster.show(Intent.DANGER, "Could not get details. Please refresh the page.");
        });
    }

    useEffect(() => {
        setRawTableData(files);
        setTotalRecords(files.length);
    }, [files]);
    function saveCloseJob() {
        setSaving(true);

        if (currentTabKey === 'FILES') {

            var jobFile = {
                description: "close job file",
                file: uploadedFile
            };

            JobService.uploadJobFile(props.id, jobFile).then(r => {
                setFileName("");
                NotificationToaster.show(Intent.SUCCESS, "The file was added successfully.");
                setSaving(false);
                setUploadedFile(null);

                setLoading(true);
                JobService.getJobFiles(props.id).then(z => {
                    setFiles(z);
                }, function () {
                    NotificationToaster.show(Intent.DANGER, "Documents could not be uploaded. Please try loading the page again.");
                });
            }, function () {
                NotificationToaster.show(Intent.DANGER, "Documents could not be uploaded. Please try loading the page again.");
            }).finally(function () {
                setLoading(false);
                setSaving(false);
            });
        };

        if (currentTabKey === 'DETAILS') {
            var closedDateLocal = closeJob.closedDate;
            const closejobClone = {
                ...closeJob,
                closedDate: moment(closeJob.closedDate).utc().format("YYYY-MM-DD HH:mm")
            };

            JobService.closeJob(props.id, closejobClone).then(
                () => {
                    const jobDetailsClone = {
                        ...jobDetails,
                        completedDate: closedDateLocal,
                        state: "Done",
                        completed: true
                    };

                    setOriginalCloseJob(jobDetailsClone);
                    setJobDetails(jobDetailsClone);
                    NotificationToaster.show(Intent.SUCCESS, "The job has been updated successfully.");
                    setSaving(false);

                    HelperFunctions.executeIfNotNull(props.onSave, jobDetailsClone);
                },
                (error) => {
                    NotificationToaster.show(Intent.DANGER, "Could not save. Please try again.");
                    setSaving(false);
                }
            )
        };

    }

    function onNameChange(e) {
        setCloseJob((prev) => {
            return {
                ...prev,
                employeeName: e.target.value
            }
        });    
    }

    function onEmailChange(e) {
        setCloseJob((prev) => {
            return {
                ...prev,
                employeeEmail: e.target.value
            }
        });
    }

    function onPositionChange(e) {
        setCloseJob((prev) => {
            return {
                ...prev,
                employeePosition: e.target.value
            }
        });
    }

    function onPhoneNumberChange(e) {
        setCloseJob((prev) => {
            return {
                ...prev,
                employeePhoneNumber: e.target.value
            }
        });
    }

    function onClosedDateChange(item) {
        setCloseJob((prev) => {
            return {
                ...prev,
                closedDate: item ? moment(item).format("YYYY-MM-DD") : null
            }
        });
    }

    function onCommentsChange(e) {
        setCloseJob((prev) => {
            return {
                ...prev,
                comments: e.target.value
            }
        });
    }

    useEffect(validateModel, [closeJob, uploadedFile])

    function showDeleteAlert(fileId) {
        setselectedFile(fileId);
        setShowDeleteConfirm(true);
    }

    function onDeleteConfirm() {
        setShowDeleteConfirm(false);
        deleteFile(selectedFile);
    }

    function onDeleteCancel() {
        setselectedFile(0);
        setShowDeleteConfirm(false);
    }

    function deleteFile(fileId) {
        var cloneFiles = [...files];
        var matchedJobIndex = cloneFiles.findIndex(e => e.jobFileId === fileId);
        if (matchedJobIndex === -1) {
            NotificationToaster.show(Intent.DANGER, "Could not remove file. Please try again.");
        }
        else {
            var fileStorageIdentifier = cloneFiles[matchedJobIndex].storageId + '.' + cloneFiles[matchedJobIndex].fileName.split('.').pop();
            JobService.deleteJobFile(props.id, fileStorageIdentifier)
                .then(
                    () => {
                        cloneFiles.splice(matchedJobIndex, 1);
                        setFiles(cloneFiles);
                        setRawTableData(cloneFiles);
                        NotificationToaster.show(Intent.SUCCESS, "The file was removed.");
                        setSaving(false);
                    },
                    () => {
                        NotificationToaster.show(Intent.DANGER, "Could not save. Please try again.");
                        setSaving(false);
                    },
                );
        }
    }

    function onFileChange(event) {
        //if files length = 0 then cancel was selected.
        var changedfileName = (event.currentTarget.files.length === 0) ? "" : event.currentTarget.files[0].name;
        setFileName(changedfileName);
        setUploadedFile((event.currentTarget.files.length === 0) ? null : event.currentTarget.files[0]);
        event.target.value = null;
    }

    function validateSelectedFile(file) {
        //vaidation will be picked up by rule is file not selected
        if (!file) {
            return "";
        }
        if (!fileTypes.includes(file.type)) {
            return "Invalid file type.";
        }
        if (file.size === 0) {
            return "The file can not be empty.";
        }
        if (file.size > maxFileSize) {
            return "The file is too large. The file must be less than 10MB in size.";
        }

        return checkForNameClash(file.name);
    }

    function checkForNameClash(name) {
        var fileHasMatch = files.some(d => d.fileName.toUpperCase() === name.toUpperCase());

        return fileHasMatch ? "The file name matches the name of another file attached to this job. Please select a different file." : "";
    }
    function onTabClick(index) {

        var clonedTabs = [
            ...tabs
        ];

        clonedTabs = clonedTabs.map(function (t, i) {
            return {
                ...t,
                selected: index === i
            };
        });

        setTabs(clonedTabs);
        setCurrentTabKey(tabs[index].key);
    }

    function onDownloadFile(storageId) {
        setDownloadingFile(true);
        JobService.getJobFileToken(storageId).then(function (response) {
            BlobStorageService.downloadFile(response.storageName, response.containerName, response.fileName, response.token, response.downloadName).then(function () {
                NotificationToaster.show(
                    Intent.SUCCESS,
                    "File has been fetched successfully, download will now begin"
                );
                setDownloadingFile(false);
            }, function () {
                NotificationToaster.show(Intent.DANGER, "Unable to download your file, please try again.");
                setDownloadingFile(false);
            });
        }, function () {
            NotificationToaster.show(Intent.DANGER, "Unable to download your file, please try again.");
            setDownloadingFile(false);
        })
    }

    function validateModel() {
        let rules = [];
        if (closeJob.employeePhoneNumber !== null && closeJob.employeePhoneNumber !== "") {
            rules.push({ fieldName: "employeePhoneNumber", required: true, type: "phone" });
        }

        if (closeJob.employeeEmail !== null && closeJob.employeeEmail !== "") {
            rules.push({ fieldName: "employeeEmail", required: false, type: "email" });
        }

        var warning = validateSelectedFile(uploadedFile);
        let externalRules = [];
        if (warning !== "") {
            externalRules.push({
                fieldName: "fileName",
                valid: false,
                errorMessage: warning
            });
        }

        validate(rules, closeJob);
        validateFile([], [], externalRules);
    }

    useEffect(() => {

        let data = [];

        if (rawTableData != null) {
            data = rawTableData.map((d) => {
                var rowData = [
                    <Link to={'#'} key={`link-${d.storageId}`} onClick={() => onDownloadFile(d.storageId)} disabled={downloadingFile}>{d.fileName}</Link>,
                    <DateToLocal key={`date-${d.storageId}`} >{d.createDate}</DateToLocal>];

                if (isInternalJobEditor) {
                        rowData.push(<div className="button-row-small" key={`btn-${d.storageId}`}>
                            <Button onClick={() => showDeleteAlert(d.jobFileId)} icon="trash" iconOnly={true} text="Delete File" large={false} minimal={true}></Button>
                        </div>);
                }
                return rowData;
            })
        }

        setTableRows(data);
    }, [rawTableData]);

    return (

        <UserAccess perform={requiredAction}
            yes={() => (
                <Fragment>
                    <div className="row">
                        <h2>Close Job</h2>
                        <div className="spacer-bottom">
                            <Tabs tabs={tabs} onClick={onTabClick} loading={props.loading} />
                        </div>

                        <ShowHide
                            evaluator={currentTabKey === 'DETAILS'}
                            show={(
                                <Fragment>

                                    <FormDateInput
                                        id="closed-date"
                                        headingText="Closed Date:"
                                        onChange={onClosedDateChange}
                                        value={closeJob.closedDate}
                                        loading={loading}
                                        minDate={moment("1900-01-01").toDate()}
                                        maxDate={moment().toDate()}
                                        disabled={!isInternalJobEditor || props.saving || props.pending}
                                        dangerHelperText={errors.closedDate}
                                    />
                                    <FormTextArea
                                        id="comments"
                                        headingText="Comments:"
                                        loading={loading}
                                        value={closeJob.comments}
                                        onChange={onCommentsChange}
                                        disabled={!isInternalJobEditor || props.saving || props.pending}
                                        maxLength={1000}
                                        wide
                                    >
                                    </FormTextArea>
                                    <h3>Person Providing The Information</h3>
                                    <FormTextInput
                                        id="employee-name"
                                        loading={loading}
                                        value={closeJob.employeeName}
                                        headingText="Name:"
                                        onChange={onNameChange}
                                        disabled={!isInternalJobEditor || props.saving || props.pending}
                                        maxLength={255}
                                        dangerHelperText={errors.name} >
                                    </FormTextInput>

                                    <FormTextInput
                                        id="employee-position"
                                        loading={loading}
                                        value={closeJob.employeePosition}
                                        headingText="Position:"
                                        onChange={onPositionChange}
                                        disabled={!isInternalJobEditor || props.saving || props.pending}
                                        maxLength={255}
                                        dangerHelperText={errors.position} >
                                    </FormTextInput>

                                    <FormTextInput
                                        id="employee-email"
                                        loading={loading}
                                        value={closeJob.employeeEmail}
                                        headingText="Email Address:"
                                        onChange={onEmailChange}
                                        disabled={!isInternalJobEditor || props.saving || props.pending}
                                        maxLength={255}
                                        dangerHelperText={errors.employeeEmail} >
                                    </FormTextInput>

                                    <FormTextInput
                                        id="phone-number"
                                        loading={loading}
                                        value={closeJob.employeePhoneNumber}
                                        headingText="Phone Number:"
                                        onChange={onPhoneNumberChange}
                                        disabled={!isInternalJobEditor || props.saving || props.pending}
                                        maxLength={50}
                                        dangerHelperText={errors.employeePhoneNumber} >
                                    </FormTextInput>
                                </Fragment>
                            )}
                        />

                        <ShowHide
                            evaluator={currentTabKey === 'FILES'}
                            show={(
                                <Fragment>
                                    <h2>Attached Files</h2>
                                    <FormFileInput
                                        text={fileName}                                        
                                        buttonText="Browse"
                                        headingText="Use the file uploader to upload a document."
                                        helperText="The file is in PDF, PNG, JPG, CSV, word and excel format. The file has a maximum size of 10MB. "
                                        fill={false}
                                        onChange={onFileChange}
                                        hasSelection={fileName !== ''}
                                        dangerHelperText={fileErrors.fileName}
                                        disabled={!isInternalJobEditor || props.saving || files.length === maxNoFiles}
                                    />

                                    <div className="spacer-bottom">
                                        <ListingTable
                                            id="listing-table-files"
                                            headers={tableHeaders}
                                            loadingData={loading}
                                            noDataMessage={"You have no documents attached."}
                                            data={tableRows}
                                            totalRecordCount={totalRecords}
                                            sortable={false}
                                            pageable={false}
                                        />

                                        <AlertConfirm
                                            title="Please confirm"
                                            isOpen={showDeleteConfirm}
                                            onConfirm={onDeleteConfirm}
                                            onCancel={onDeleteCancel}
                                        >
                                            <p>Are you sure you want to delete this file?</p>
                                        </AlertConfirm>
                                    </div>

                                </Fragment>
                            )}
                        />

                        <AlertUnsavedData
                            isDirty={isDataDirty}>
                        </AlertUnsavedData>
                        <ShowHide
                            evaluator={isInternalJobEditor}
                            show=
                            {
                                <div className="inline-item">
                                    <ButtonSave
                                        onClick={saveCloseJob}
                                        disabled={saving}
                                        simpleDisabled={(!isValid && currentTabKey === 'DETAILS') || (currentTabKey === 'FILES' && (files.length === maxNoFiles || fileName === '' || !fileValid))}
                                        loading={loading}
                                    ></ButtonSave>
                                </div>
                            }
                        >
                        </ShowHide>

                    </div>
                </Fragment>

            )}
            no={() => (
                <PageRestricted />
            )}
        />

    );
}
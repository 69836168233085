import React, { useEffect, useState } from 'react';
import { Modal, NotificationToaster } from 'components/notifications';
import { Tabs } from 'components/navigation';
import { ActivitiesTab } from 'pages/fleet/day-detail-modal/activities-tab';
import { SpeedDataTab } from 'pages/fleet/day-detail-modal/speed-data-tab';
import { HistoryTab } from 'pages/fleet/day-detail-modal/history-tab';
import { NotesTab } from 'pages/fleet/day-detail-modal/notes-tab';
import { AssetService } from 'services';
import PropTypes from "prop-types";
import { Intent } from '@blueprintjs/core';
import { AssetStatus } from 'components/status/AssetStatus';
import { ShowHide } from 'components/layout';
import { FormDatePeriodSelector, FormHeading } from 'components/form-fields';
import { DateToLocal } from 'components/dates';
import './DayDetailModal.css';

export function DayDetailModal(props) {
    const { showDayDetailModal, closeDayDetailModal, assetId, date, statusAttributeMappings, ownershipAttributeMappings, openTabName } = props;
    const periodSelectorDateFormat = "DD MMM YYYY";
    const defaultTabsLength = 3;
    const tabLengthWithSpeedData = 4
    const [assetStatusId, setAssetStatusId] = useState(0);
    const [assetOwnershipId, setAssetOwnershipId] = useState(0);
    const [assetRegistration, setAssetRegistration] = useState("");
    const [dayDetailDate, setDayDetailDate] = useState(props.date);
    const [tachoDataForDayDetail, setTachoDataForDayDetail] = useState({});
    const [isTachoDataLoading, setIsTachoDataLoading] = useState(true);
    const [harshBrakingData, setHarshBrakingData] = useState([]);
    const [dayDetailTitle, setDayDetailTitle] = useState(<></>)
    const [note, setNote] = useState("");
    const [loading, setLoading] = useState(true);
    const [selectedTab, setSelectedTab] = useState("ACTIVITIES");
    const [tabs, setTabs] = useState([
        { title: "Activities", selected: true, key: "ACTIVITIES" },
        { title: "History", selected: false, key: "HISTORY" },
        { title: "Notes", selected: false, key: "NOTES" }
    ]);

    useEffect(() => {
        if (assetId && dayDetailDate && showDayDetailModal) {
            callTachDataForDayDetail();
            callHarshDataForDayDetail();
            callNoteForDayDetail();
        }

    }, [assetId, dayDetailDate, showDayDetailModal]);

    useEffect(() => {
        if (!showDayDetailModal) {
            resetTabs();
        }
    }, [showDayDetailModal]);

    useEffect(() => {
        if (openTabName && openTabName.length > 0) {
            selectTab(openTabName);
        }
    }, [isTachoDataLoading, openTabName]);

    useEffect(() => {
        if (note) {
            setTabs(tabs.map(function (t) {
                if (t.key === "NOTES") {
                    return {
                        ...t,
                        icon: "comment"
                    }
                }
                return t;
            }));
        }
        else {
            setTabs(tabs.map(function (t) {
                if (t.key === "NOTES") {
                    return {
                        title: t.title,
                        selected: t.selected,
                        key: t.key
                    }
                }
                return t;
            }));
        }
    }, [note]);

    useEffect(() => {
        setDayDetailTitle(
            <div className="inline-items">
                <FormHeading headingLevel="h2">Day Detail</FormHeading>
                <FormHeading headingLevel="h2">{<DateToLocal format='DD MMM YYYY'>{dayDetailDate}</DateToLocal>}</FormHeading>
                <AssetStatus statusId={assetStatusId} ownershipId={assetOwnershipId} isLoading={loading && isTachoDataLoading} statusAttributeMappings={statusAttributeMappings} ownershipAttributeMappings={ownershipAttributeMappings} />
                <FormHeading headingLevel="h2" isLoading={loading && isTachoDataLoading}><a href={`${window.env.VISION_URL}/#/Fleet/RedirectToProfile?assetId=${assetId}`}>{assetRegistration}</a></FormHeading>
            </div>)
    }, [assetStatusId, assetOwnershipId, assetRegistration, loading, isTachoDataLoading, statusAttributeMappings, ownershipAttributeMappings, dayDetailDate]);

    function onDayChange(newDate) {
        setDayDetailDate(newDate);
    }

    function onTabClick(index) {
        setTabs(tabs.map(function (t, i) {
            return {
                ...t,
                selected: index === i
            }
        }));

        setSelectedTab(tabs[index].key);
    }

    function resetTabs() {
        setSelectedTab("ACTIVITIES");
        setTabs([
            { title: "Activities", selected: true, key: "ACTIVITIES" },
            { title: "History", selected: false, key: "HISTORY" },
            { title: "Notes", selected: false, key: "NOTES" }
        ]);
        setNote("");
        setAssetStatusId(0);
        setAssetOwnershipId(0);
        setAssetRegistration("");
        setTachoDataForDayDetail({});
        setHarshBrakingData([]);
    }

    function closeModal() {
        resetTabs();
        closeDayDetailModal();
    }

    function callTachDataForDayDetail() {
        setIsTachoDataLoading(true);
        AssetService.getTachoDataForAsset(assetId, dayDetailDate).then((response) => {
            if (response != null) {
                setTachoDataForDayDetail(response);
                setAssetStatusId(response.vehicleStatus);
                setAssetOwnershipId(response.vehicleOwnership);
                setAssetRegistration(response.vehicleRegistration);

                if (response.hasSpeedData && tabs.length === defaultTabsLength) {
                    setTabs([
                        { title: "Activities", selected: true, key: "ACTIVITIES" },
                        { title: "Speed Data", selected: false, key: "SPEED_DATA" },
                        { title: "History", selected: false, key: "HISTORY" },
                        { title: "Notes", selected: false, key: "NOTES" }
                    ]);
                }
                else if (!response.hasSpeedData && tabs.length === tabLengthWithSpeedData) {
                    setSelectedTab("ACTIVITIES");
                    setTabs([
                        { title: "Activities", selected: true, key: "ACTIVITIES" },
                        { title: "History", selected: false, key: "HISTORY" },
                        { title: "Notes", selected: false, key: "NOTES" }
                    ]);
                }
            }
        }).catch(() => {
            NotificationToaster.show(Intent.DANGER, "Failed to get tacho data");
            setIsTachoDataLoading(false);
        }).finally(() => {
            setLoading(false);
            setIsTachoDataLoading(false);
        });
    }

    function callHarshDataForDayDetail() {
        setLoading(true);
        AssetService.getHarshBrakingDataForAsset(assetId, dayDetailDate).then((response) => {
            if (response != null) {
                setHarshBrakingData(response);
            }
        }).catch(() => {
            NotificationToaster.show(Intent.DANGER, "Failed to get harsh braking data", false);
        }).finally(() => {
            setLoading(false);
        });
    }

    function callNoteForDayDetail() {
        AssetService.getNote(assetId, dayDetailDate).then((response) => {
            if (response && response.note) {
                setNote(response.note);
            }
        }).catch(() => {
            NotificationToaster.show(Intent.DANGER, `Failed to get note`, false);
        }).finally(() => {
            setLoading(false);
        });
    }

    function selectTab(tabName) {
        let tabIndex = tabs.findIndex(t => t.key === tabName);
        if (tabIndex >= 0) {
            onTabClick(tabIndex);
        }
    }

    return (
        <Modal
            isOpen={showDayDetailModal}
            clickOutsideClose
            onClose={closeModal}
            title={dayDetailTitle}
            icon="warning-sign"
            intent={Intent.DANGER}
            confirmButtonText="Dismiss"
            onConfirm={props.closeDayDetailModal}
            fullScreen
            className="day-detail-modal"
        >
            <div className='align-tabs-and-date'>
                <FormDatePeriodSelector
                    id="listing-period-new"
                    periodType="day"
                    periodsToShow={0}
                    startDate={date}
                    onChange={onDayChange}
                    dateFormat={periodSelectorDateFormat}
                    showCalendar={true}
                    showText={false}
                    usePortal={false}
                />
                <Tabs tabs={tabs} onClick={onTabClick} loading={loading || props.loading} />
            </div>

            <ShowHide
                evaluator={selectedTab === "ACTIVITIES"}
                show={<ActivitiesTab
                    lastSearchPayload={props.lastSearchPayload}
                    activitiesApiResponse={tachoDataForDayDetail}
                    harshBrakingApiResponse={harshBrakingData}
                    isLoading={isTachoDataLoading}
                    vehicleActivityTypes={props.vehicleActivityTypes}
                    assetDetails={{ assetId, assetStatusId, assetOwnershipId, assetRegistration, statusAttributeMappings, ownershipAttributeMappings }}
                    date={dayDetailDate}
                    refreshTachoData={callTachDataForDayDetail} />
                }
            />

            <ShowHide
                evaluator={selectedTab === "SPEED_DATA"}
                show={<SpeedDataTab
                    assetId={assetId}
                    date={dayDetailDate}
                    harshBrakingData={harshBrakingData}
                    tachoData={tachoDataForDayDetail} />
                }
            />

            <ShowHide
                evaluator={selectedTab === "HISTORY"}
                show={<HistoryTab />}
            />

            <ShowHide
                evaluator={selectedTab === "NOTES"}
                show={<NotesTab
                    assetId={assetId}
                    date={dayDetailDate}
                    note={note}
                    setNote={setNote} />
                }
            />
        </Modal>
    )
}

DayDetailModal.defaultProps = {
    showDayDetailModal: false,
    closeDayDetailModal: () => { },
    assetId: 0,
    date: "",
    statusAttributeMappings: [],
    ownershipAttributeMappings: []
};

DayDetailModal.propTypes = {
    showDayDetailModal: PropTypes.bool.isRequired,
    closeDayDetailModal: PropTypes.func,
    assetId: PropTypes.number,
    date: PropTypes.string,
    statusAttributeMappings: PropTypes.array,
    ownershipAttributeMappings: PropTypes.array,
    vehicleActivityTypes: PropTypes.array.isRequired,
    lastSearchPayload: PropTypes.object,
    openTabName: PropTypes.string,
};
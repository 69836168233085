import React, { useEffect, useState } from "react";
import { FormHeading, FormTextArea } from "components/form-fields";
import { Button, ButtonSave } from "components/buttons";
import PropTypes from "prop-types";
import { AssetService } from "services";
import { NotificationInline, NotificationToaster } from "components/notifications";
import { Intent } from "@blueprintjs/core";

export function NotesTab(props) {
    const { assetId, date, note, setNote } = props;
    const [newNote, setNewNote] = useState(note)
    const [isSaving, setIsSaving] = useState(false)

    useEffect(() => {
        setNewNote(note)
    }, [note])

    function onNoteChange(event) {
        setNewNote(event.target.value);
    }

    function handleSaveClick() {
        setIsSaving(true);
        AssetService.saveNote(assetId, date, newNote).then(() => {
            setNote(newNote);
            NotificationToaster.show(Intent.SUCCESS, "Saved note", false);
        }).catch(() => {
            NotificationToaster.show(Intent.DANGER, "Failed to save note", false);
        }).finally(() => {
            setIsSaving(false);
        });
    }

    function handleDeleteClick() {
        setIsSaving(true);
        AssetService.deleteNote(assetId, date).then(() => {
            setNewNote("");
            setNote("");
            NotificationToaster.show(Intent.SUCCESS, "Deleted note", false);
        }).catch(() => {
            NotificationToaster.show(Intent.DANGER, "Failed to delete note", false);
        }).finally(() => {
            setIsSaving(false);
        });
    }

    return (
        <div className='spacer-top-small'>
            <FormHeading headingLevel="h3">Notes</FormHeading>
            <FormTextArea
                id="asset-notes"
                maxLength={1000}
                disabled={isSaving}
                helperText="Please type your note here:"
                value={newNote}
                onChange={onNoteChange}
            ></FormTextArea>
            <NotificationInline intent={Intent.WARNING} text="Note is too long, must be below 1000 characters" show={newNote.length >= 1000} isShort />
            <div className="button-row button-row-stacked-mobile spacer-bottom">
                <ButtonSave
                    onClick={handleSaveClick}
                    disabled={newNote.length >= 1000}
                    isSaving={isSaving}
                />
                <Button
                    onClick={handleDeleteClick}
                    icon="trash"
                    text="delete"
                    disabled={isSaving}
                    intent='DANGER'
                />
            </div>
        </div>
    )
}

NotesTab.defaultProps = {
    assetId: 0,
    date: "",
    note: "",
    setNote: () => { }
};

NotesTab.propTypes = {
    assetId: PropTypes.number.isRequired,
    date: PropTypes.string.isRequired,
    note: PropTypes.string.isRequired,
    setNote: PropTypes.func.isRequired
};
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState, Fragment, useEffect } from 'react';
import { ShowHide } from "components/layout";
import moment from "moment";
import { Tooltip, Position, Icon } from "@blueprintjs/core";
import { DateToLocal } from "components/dates";

import './CalendarCell.css';

export function CalendarCell(props) {
    const [shiftTimesJsx, setShiftTimesJsx] = useState(<></>);
    const [activityShiftsJsx, setActivityShiftsJsx] = useState(<></>);
    const [tooltipDisabled, setTooltipDisabled] = useState(true);
    const [shiftTimesTooltipJsx, setShiftTimesTooltipJsx] = useState(<></>);

    function getTimeInfo(activityInfo, showEllipsis) {
        let ellipsis = showEllipsis ? <>&hellip;</> : <></>;
        return <><span className="calendar-cell-activity-square" style={{ backgroundColor: activityInfo.activityType.colour }} ></span><span className="calendar-cell-times">{moment(activityInfo.dayStartTimeBst).format('HH:mm')} - {moment(activityInfo.dayEndTimeBst).format('HH:mm')} </span>{ellipsis}</>;
    }

    useEffect(() => {
        if (props.data && props.data.activityInfo) {
            if (props.data.activityInfo.length < 1) {
                setTooltipDisabled(true);
                setShiftTimesTooltipJsx(<></>);
                setShiftTimesJsx(<></>);
                setActivityShiftsJsx(<></>);
                return;
            };

            if (props.data.activityInfo.length > 2) {
                setTooltipDisabled(false);
                let jsx = props.data.activityInfo.map((a, index) => {
                    return (<span className="calendar-cell-tooltip" key={`calendar-cell-times-${props.dayIndex}-${index}`}>{getTimeInfo(a)}</span>)
                });
                setShiftTimesTooltipJsx(jsx);
                setShiftTimesJsx((<>{getTimeInfo(props.data.activityInfo[0], false)}{getTimeInfo(props.data.activityInfo[1], true)}</>));
            } else {
                let jlx = props.data.activityInfo.map((c) => {
                    return (getTimeInfo(c))
                });
                setTooltipDisabled(true);
                setShiftTimesTooltipJsx(<></>);
                setShiftTimesJsx(jlx)
            }

            let activityJsx = props.data.activityInfo.map((a, index) => {
                const currentActivityKey = `${a.shiftStartDate}-${a.shiftEndDate}`;
                const selectedActivityKey = props.selectedActivity
                    ? `${props.selectedActivity.shiftStartDate}-${props.selectedActivity.shiftEndDate}`
                    : null;

                const isSelected = selectedActivityKey === currentActivityKey;

                const activityStart = moment(a.shiftStartDate);
                const activityEnd = moment(a.shiftEndDate);
                const dayStart = moment(props.data.date).startOf('day');
                const dayEnd = moment(props.data.date).endOf('day');

                const className = classNames('calendar-cell-activity-bar', {
                    'selected': isSelected,
                    'start': isSelected && activityStart.isSameOrAfter(dayStart) && activityEnd.isAfter(dayEnd),
                    'middle': isSelected && activityStart.isBefore(dayStart) && activityEnd.isAfter(dayEnd),
                    'end': isSelected && activityStart.isBefore(dayStart) && activityEnd.isSameOrBefore(dayEnd),
                    'single': isSelected && activityStart.isSameOrAfter(dayStart) && activityEnd.isSameOrBefore(dayEnd),
                });

                let activityTooltipJsx = (
                    <>
                        <span className="calendar-cell-tooltip">{a.activityType.name}</span>
                        <span className="calendar-cell-tooltip">Entry start: <DateToLocal>{a.shiftStartDate}</DateToLocal></span>
                        <span className="calendar-cell-tooltip">Entry end: <DateToLocal>{a.shiftEndDate}</DateToLocal></span>
                    </>
                );

                return (
                    <div
                        className="calendar-cell-activity-bar-container"
                        style={{ left: a.leftPercent, width: a.durationPercentage }}
                        key={`calendar-cell-activity-bar-${props.dayIndex}-${index}`}
                        onClick={(e) => props.handleActivitySelectedChange(e, a)}
                        onKeyDown={(e) => { }}
                        role="switch" tabIndex={0} aria-checked={false}
                    >
                        <Tooltip position={Position.TOP} content={activityTooltipJsx}>
                            <div
                                className={className}
                                style={{
                                    backgroundColor: a.activityType.colour
                                }}
                            >
                            </div>
                        </Tooltip>
                    </div>
                )
            });

            setActivityShiftsJsx(activityJsx);
        }
    }, [props.data, props.selectedActivity]);

    return (
        <Fragment>
            <div className={classNames({ "calendar-cell-selected": props.isSelected, "calendar-cell-week-odd": props.weekOdd }, 'calendar-cell', 'no-select')}
                onClick={(event) => props.handleDaySelectedChange(event, props.dayIndex)} onKeyDown={() => { }} role="switch" tabIndex={0} aria-checked={props.isSelected}>
                <div className="calendar-cell-info calendar-cell-info-top">
                    <span >
                        {moment(props.data.date).format("DD MMM").toUpperCase()}
                    </span>
                    <span className='calendar-cell-icons'>
                        {props.cellIcons.map(function (icon) {
                            return (
                                <ShowHide
                                    key={`icon-${props.dayIndex}-${icon.icon}`}
                                    evaluator={icon.evaluator}
                                    show={
                                        <div onClick={icon.clickEvent} onKeyDown={() => { }} role="switch" tabIndex={0} aria-checked={props.isSelected}>
                                            <Tooltip position={Position.TOP} content={icon.tooltipContent}>
                                                <Icon icon={icon.icon} className={classNames({ "cursor-pointer": icon.clickEvent })} style={{ color: icon.colour }} />
                                            </Tooltip>
                                        </div>
                                    }
                                >
                                </ShowHide>
                            )
                        })}
                    </span>
                </div>

                <div className="calendar-cell-activity">
                    {activityShiftsJsx }
                </div>

                <ShowHide
                    evaluator={props.showTimes}
                    show={
                        <Tooltip position={Position.TOP} content={shiftTimesTooltipJsx} disabled={tooltipDisabled}>
                            <div className="calendar-cell-info calendar-cell-info-bottom">
                                {shiftTimesJsx}
                            </div>
                        </Tooltip>
                    }
                    hide={
                        <div className="calendar-cell-info calendar-cell-info-bottom"></div>
                    }
                ></ShowHide>

            </div>
        </Fragment>
    );
}

CalendarCell.defaultProps = {
    loading: false,
    cellIcons: []
};

CalendarCell.propTypes = {
    data: PropTypes.object.isRequired,
    loading: PropTypes.bool,
    isSelected: PropTypes.bool,
    dayIndex: PropTypes.number.isRequired,
    handleDaySelectedChange: PropTypes.func,
    weekOdd: PropTypes.bool,
    showTimes: PropTypes.bool,
    cellIcons: PropTypes.array,
    handleActivitySelectedChange: PropTypes.func,
    selectedActivity: PropTypes.object
};
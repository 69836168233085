import React, { Fragment, useEffect, useState } from "react";
import { Intent } from "@blueprintjs/core";

import { UserAccess, UserHasAccess } from "components/user-access";
import { useParams } from "react-router-dom";
import { JobService, BlobStorageService } from "services";
import { PageRestricted } from "pages/errors/page-restricted";
import { Breadcrumbs } from "components/navigation";
import { NotificationInline, NotificationToaster } from "components/notifications";
import { ShowHide } from "components/layout";
import { DataDisplayTable } from "components/data-display";
import { DateToLocal } from "components/dates";
import { ImageViewer } from "components/notifications/ImageViewer";
import { Button } from "components/buttons";
import axios from 'axios';
import { ListingTable } from "components/listing";

export function CloseJobView(props) {

    const { id } = useParams();
    const requiredAction = "AuditsInspections:View";
    const canAccessPage = UserHasAccess(requiredAction);
    const [showImageModal, setShowImageModal] = useState(false);
    const [image, setImage] = useState("");
    const [headerSections, setHeaderSections] = useState([]);
    const [commentSection, setCommentSection] = useState([]);
    const [informantSections, setInformantSections] = useState([]);
    const [jobFiles, setJobFiles] = useState([]);
    const [loadingData, setLoadingData] = useState(true);
    const [permissionDenied, setPermissionDenied] = useState(false);
    const [tableHeaders] = useState(["Name", "Date", "Download","Image"]);
    const [tableRows, setTableRows] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [rawTableData, setRawTableData] = useState([]);
    const [downloadingFile, setDownloadingFile] = useState(false);
    const [showAttachements, setShowAttachements] = useState(false);

    useEffect(() => {

        if (!canAccessPage) {
            return;
        }
        loadJob(id);

    }, [id]);

    function loadJob(jobId) {
        setLoadingData(true);

        axios.all([JobService.getCompletedVisit(jobId), JobService.getJobFiles(jobId), JobService.getJob(jobId)]).then(axios.spread(function (completedVisitResponse, jobFilesResponse, jobResponse) {
            setJobFiles(jobFilesResponse);

            var headers = [];
            var comment = [];
            if (jobResponse != null && jobResponse.state === "Done") {
                headers = [
                    {
                        header: "Completed Date",
                        value: (
                            <DateToLocal format="DD/MM/YYYY">
                                {completedVisitResponse.closedDate}
                            </DateToLocal>
                        ),
                        key: 'completed-date'
                    },
                    {
                        header: "Product",
                        value: completedVisitResponse.productName,
                        key: 'product'
                    },
                    {
                        header: "Location",
                        value: completedVisitResponse.locationName,
                        key: 'location'
                    },
                    {
                        header: "Auditor",
                        value: completedVisitResponse.auditor,
                        key: 'auditor'
                    },
                    {
                        header: "Reference",
                        value: completedVisitResponse.pubsJobId != null ? completedVisitResponse.pubsJobId : jobId,
                        key: 'visit-id'
                    }
                ];

                comment = [
                    {
                        header: "Comments",
                        value: completedVisitResponse.comments,
                        key: 'comments'
                    }
                ];

                var informant = [];

                if (completedVisitResponse.employeeName != null ||
                    completedVisitResponse.employeePosition != null ||
                    completedVisitResponse.employeeEmail != null ||
                    completedVisitResponse.employeePhoneNumber != null) {
                    informant = [
                        {
                            header: "Name",
                            value: completedVisitResponse.employeeName,
                            key: 'employeeName'
                        },
                        {
                            header: "Position",
                            value: completedVisitResponse.employeePosition,
                            key: 'employeePosition'
                        },
                        {
                            header: "Email Address",
                            value: completedVisitResponse.employeeEmail,
                            key: 'employeeEmail'
                        },
                        {
                            header: "Phone Number",
                            value: completedVisitResponse.employeePhoneNumber,
                            key: 'employeePhoneNumber'
                        }
                    ];
                }
                
                setInformantSections(informant);
                setShowAttachements(jobFilesResponse.length > 0);
            }
            else {
                headers = [
                    {
                        header: "Date",
                        value: (
                            <DateToLocal format="DD/MM/YYYY">
                                {jobResponse.closedDate}
                            </DateToLocal>
                        ),
                        key: 'completed-date'
                    },
                    {
                        header: "State",
                        value: jobResponse.state === 'Failed Appointment' ? 'Failed Visit' : jobResponse.state,
                        key: 'state'
                    },
                    {
                        header: "Auditor",
                        value: jobResponse.assigneeName,
                        key: 'auditor'
                    },
                    {
                        header: "Reference",
                        value: completedVisitResponse.pubsJobId != null ? completedVisitResponse.pubsJobId : jobId,
                        key: 'visit-id'
                    }
                ];

                comment = [
                    {
                        header: "Reason",
                        value: jobResponse.failureReason,
                        key: 'product'
                    },
                    {
                        header: "Comment ",
                        value: jobResponse.failureAdditionalComments,
                        key: 'location'
                    }
                ];
            }


            setHeaderSections(headers);
            setCommentSection(comment);

            setLoadingData(false);
        }), () => {
            setPermissionDenied(true);
            NotificationToaster.show(Intent.DANGER, "Could not get details. Please refresh the page.");
        });
    }

    useEffect(() => {
        setRawTableData(jobFiles);
        setTotalRecords(jobFiles.length);
    }, [jobFiles]);

    useEffect(() => {

        let data = [];

        if (rawTableData != null) {
            data = rawTableData.map((d) => {

                var imageCtrl = d.storageFileName.includes(".png") || d.storageFileName.includes(".jpg") ?
                    <div className="button-row-small" key={`btn-${d.storageId}`}>
                        <Button onClick={() => onShowImage(d.storageFileName, d.storageName, d.token)} icon="media" iconOnly={true} text="View Image" large={false} minimal={true}></Button>
                    </div> : <div></div>;
                return [
                    d.fileName,
                    <DateToLocal key={`date-${d.storageId}`} >{d.createDate}</DateToLocal>,
                    <div className="button-row-small" key={`btn-${d.storageId}`}>
                        <Button onClick={() => onDownloadFile(d.storageId)} icon="download" iconOnly={true} text="Download File" disabled={downloadingFile} large={false} minimal={true}></Button>
                    </div>,
                    imageCtrl
                ];
            })
        }

        setTableRows(data);
    }, [rawTableData]);

    function onDownloadFile(storageId) {
        setDownloadingFile(true);
        JobService.getJobFileToken(storageId).then(function (response) {
            BlobStorageService.downloadFile(response.storageName, response.containerName, response.fileName, response.token, response.downloadName).then(function () {
                NotificationToaster.show(
                    Intent.SUCCESS,
                    "File has been fetched successfully, download will now begin"
                );
                setDownloadingFile(false);
            }, function () {
                NotificationToaster.show(Intent.DANGER, "Unable to download your file, please try again.");
                setDownloadingFile(false);
            });
        }, function () {
            NotificationToaster.show(Intent.DANGER, "Unable to download your file, please try again.");
            setDownloadingFile(false);
        })
    }

    function onHideImage() {
        setShowImageModal(false);
    }

    function onShowImage(storageFileName, storageName, token) {
        setImage(generateImageUrl(storageFileName, storageName, token));
        setShowImageModal(true);
    }

    function generateImageUrl(storageFileName, storageName, token) {
        return `${storageName}/files/${storageFileName}?${token}`;
    }

    return (
        <UserAccess
            perform={requiredAction}
            yes={() => (
                <ShowHide
                    evaluator={permissionDenied}
                    show={
                        <div className="row">
                            <Breadcrumbs items={props.breadcrumbs} />
                            <NotificationInline
                                allowClose={false}
                                show
                                text="You do not have permission to view this visit"
                                intent="danger"
                            ></NotificationInline>
                        </div>
                    }
                    hide={
                        <div className="row">
                            <Breadcrumbs items={props.breadcrumbs} />
                            <h1>{"Visit Report"}</h1>

                            <DataDisplayTable
                                data={headerSections}
                                displayInRows={false}
                                loading={loadingData}
                            />
                            <ShowHide
                                evaluator={informantSections && informantSections.length > 0}
                                show={
                                    <Fragment>
                                        <h3>Person providing the information</h3>
                                        <DataDisplayTable
                                            data={informantSections}
                                            displayInRows={false}
                                            loading={loadingData}
                                        />
                                    </Fragment>
                                }
                            />
                            <DataDisplayTable
                                data={commentSection}
                                displayInRows={false}
                                loading={loadingData}
                            />
                            <ShowHide
                                evaluator={showAttachements}
                                show={
                                    <Fragment>
                                        <div className="spacer-bottom">
                                            <h3>Attachments</h3>
                                            <ListingTable
                                                id="listing-table-files"
                                                headers={tableHeaders}
                                                loadingData={loadingData}
                                                noDataMessage={"You have no documents attached."}
                                                data={tableRows}
                                                totalRecordCount={totalRecords}
                                                sortable={false}
                                                pageable={false}
                                            />
                                        </div>
                                    </Fragment>
                                }
                            />


                            <ImageViewer
                                isOpen={showImageModal}
                                onClose={onHideImage}
                                mediaSource={image}
                            />
                        </div>
                    }
                />
            )}
            no={() => <PageRestricted />}
        />
    );
}

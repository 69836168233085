import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { ReportList } from "components/reporting/ReportList";
import { NotificationInline } from "components/notifications";
import { Tabs, Tab, MenuItem } from "@blueprintjs/core";
import { Panel } from "components/panel";
import { ActivitiesList, InfoPanel } from 'components/fleet';
import { ShowHide } from "components/layout";
import { HarshBrakingPanel } from "components/fleet/HarshBrakingPanel";
import { ActivitiesGraph } from "components/fleet/ActivitiesGraph";
import { AssignDriverModal } from "pages/fleet/assign-driver-modal/AssignDriverModal";
import { UnassignDriverModal } from "pages/fleet/unassign-driver-modal/UnassignDriverModal";
import { UserHasAccess } from "components/user-access";
import { AssignJourneyModal } from "pages/fleet/assign-journey-modal";
import { UnassignJourneyModal } from "pages/fleet/unassign-journey-modal";
import './ActivitiesTab.css';
import { DateToLocal } from "components/dates";
import { GetFilesButton } from "components/fleet/GetFilesButton";

export function ActivitiesTab(props) {
    const summaryColor = "#008CFF";
    const harshBrakingColour = "#F0578A";

    const activitiesSummaryTabId = "SUMMARY";
    const journeyNameMaxLength = 14;

    const vehicleUnitUploaded = 1;
    const vehicleUploadedDrivingNoCard = 2;
    const vehicleActivityTakenFromAnotherSource = 3;
    const assignedJourney = 4;
    const driverAssignedToVehicleData = 5;

    const assignDriversPermission = "Fleet:AssignWithoutCard";
    const updateCommentsPermission = "Fleet:Comments";
    const canAssignDrivers = UserHasAccess(assignDriversPermission);
    const canUpdateComments = UserHasAccess(updateCommentsPermission);

    const [reportDownloading, setReportDownloading] = useState(false);
    const [displayedActivities, setDisplayedActivities] = useState([]);
    const [undisplatedActivities, setUndisplayedActivities] = useState([]);
    const [cardIwPanelData, setCardIwPanelData] = useState([]);
    const [harshBrakingPanelData, setHarshBrakingPanelData] = useState(<></>);
    const [dataSelectTabs, setDataSelectTabs] = useState([]);
    const [currentActivityTab, setCurrentActivityTab] = useState(activitiesSummaryTabId);
    //CardIw for assigning and unassigning driver and journey
    const [cardIwForAction, setCardIwForAction] = useState(null);
    const [showAssignDriverModal, setShowAssignDriverModal] = useState(false);
    const [showUnassignModal, setShowUnassignModal] = useState(false);
    const [showAssignJourneyModal, setShowAssignJourneyModal] = useState(false);
    const [showUnassignJourneyModal, setShowUnassignJourneyModal] = useState(false);
    const [highlightedActivity, setHighlightedActivity] = useState(null);

    function onReportDownloading(isDownloading) {
        setReportDownloading(isDownloading);
    }

    function getColourFromType(vehicleActivityType) {
        if (!vehicleActivityType) {
            return summaryColor;
        }
        return props.vehicleActivityTypes.find((t) => t.id === vehicleActivityType).colours;
    }

    function formatJourneyTypeName(journeyName) {
        if (!journeyName) {
            return "";
        }
        if (journeyName.length <= journeyNameMaxLength) {
            return journeyName;
        }
        return String(journeyName).substring(0, journeyNameMaxLength) + "...";
    }

    function getPanelTitleFromType(vehicleActivityType, journeyName) {
        switch (vehicleActivityType) {
            case vehicleUnitUploaded: return "Vehicle Unit Uploaded";
            case vehicleUploadedDrivingNoCard: return "Vehicle Upload - Driving, No Card Inserted";
            case vehicleActivityTakenFromAnotherSource: return "Vehicle activity taken from another source";
            case assignedJourney: return `Assigned Journey - Journey Type: ${formatJourneyTypeName(journeyName)}`;
            case driverAssignedToVehicleData: return "Driver Assigned to Vehicle Data";
            default: return "Summary";
        }
    }

    function formatHarshBraking(data) {
        if (data.length !== 0)
            return <HarshBrakingPanel items={data} leftColor={harshBrakingColour} />;
        else
            return <></>;
    }

    function resetData() {
        //Set Activities pane:
        setDisplayedActivitiesData(activitiesSummaryTabId);
    }

    function refreshData() {
        if (!props.activitiesApiResponse) { return; }
        let summary = props.activitiesApiResponse.summary;
        //Tabs:
        setCardIwTabs(props.activitiesApiResponse.cardIws, summary);
        //Panels:
        setCardIwPanels(props.activitiesApiResponse.cardIws, summary);
    }

    useEffect(() => {
        resetData();
        refreshData();
    }, [props.assetDetails, props.date, props.activitiesApiResponse]);

    useEffect(() => {
        if (props.harshBrakingApiResponse && props.harshBrakingApiResponse.length !== 0) {
            setHarshBrakingPanelData(formatHarshBraking(props.harshBrakingApiResponse));
        }
    }, [props.harshBrakingApiResponse]);

    function filterActivitiesByDate(activities, date) {
        return activities.filter((a) => moment(a.startDate).add("minuite", a.duration).startOf("day").isSame(moment(date).startOf("day")));
    }

    function setDisplayedActivitiesData(id) {
        setCurrentActivityTab(id);
        setUndisplayedActivities([]);
        if (!id) {
            return;
        }
        if (String(id).startsWith("CardIw")) {
            let cardIw = props.activitiesApiResponse.cardIws.find((c) => c.cardIwId === parseInt(id.replace("CardIw", "")));
            setDisplayedActivities(() => {
                if (cardIw) {
                    const activities = filterActivitiesByDate(cardIw.activities, props.date);
                    return activities.map((a) => {
                        return Object.assign(a, { bgColor: getColourFromType(cardIw.vehicleActivityType) });
                    });
                }
                else {
                    return [];
                }
            });
            //set 'undisplayed' activities, this is the grey data for the graph:
            setUndisplayedActivities(() => {
                let response = [];
                let subsequentActivities = props.activitiesApiResponse.cardIws.filter((c) => c.cardIwId > parseInt(id.replace("CardIw", "")));
                if (subsequentActivities && subsequentActivities.length > 0) {
                    let activities = subsequentActivities.map((a) => {
                        return a.activities;
                    });
                    response = activities.flat();
                }
                //Add any current activities that were stripped out because they went over midnight:
                let thisCardIw = props.activitiesApiResponse.cardIws.find((c) => c.cardIwId === parseInt(id.replace("CardIw", "")));
                if (thisCardIw) {
                    let tomorrowsActivities = filterActivitiesByDate(thisCardIw.activities, moment(props.date).add(1, 'days').format("YYYY-MM-DD"));
                    response.push(...tomorrowsActivities);
                }
                return response;
            });
        }
        else {
            //Summary tab:
            setDisplayedActivities(() => {
                if (props.activitiesApiResponse && props.activitiesApiResponse.cardIws && props.activitiesApiResponse.cardIws.length > 0) {
                    return getAllActivitiesFromCardIws(props.activitiesApiResponse.cardIws);
                }
                else {
                    return [];
                }
            });
        }
    }

    function getAllActivitiesFromCardIws(cardIws) {
        return cardIws.map((c) => {
            const activities = filterActivitiesByDate(c.activities, props.date);
            return activities.map((a) => {
                return Object.assign(a, { bgColor: getColourFromType(c.vehicleActivityType) });
            });
        }).flat();
    }

    function areSameDate(date1, date2) {
        return moment(date1).isSame(date2, 'day');
    }

    function setCardIwTabs(cardIws, summary) {
        let dataSelectTabData = [];
        if (summary) {
            //Summary tab
            dataSelectTabData.push({ uid: activitiesSummaryTabId, color: summaryColor, title: `${moment(props.date).format("DD MMM YYYY")} Summary`, selected: true, key: "SUMMARY", comment: null });
        }
        if (cardIws) {
            cardIws.map((c) => {
                dataSelectTabData.push({ uid: `CardIw${c.cardIwId}`, color: getColourFromType(c.vehicleActivityType), title: <><DateToLocal format="DD MMM YYYY HH:mm">{c.startDate}</DateToLocal> - <DateToLocal format={areSameDate(c.startDate, c.endDate) ? "HH:mm" : "DD MMM YYYY HH:mm" }>{c.endDate}</DateToLocal></>, selected: false, key: c.cardIwId, comment: c.comment });
            });
        }

        setDataSelectTabs(() => dataSelectTabData.map((d) => {
            return (
                <Tab className="summary-tab-item" id={d.uid} key={d.uid} >
                    <Panel color={d.color}>
                        {d.title} {d.comment !== null ? <i className={'fa fa-solid fa-comment'} ></i> : ""}
                    </Panel>
                </Tab>
            );
        }));
    }

    function handleCardIwCommentUpdate() {
        props.refreshTachoData();
    }

    function setCardIwPanels(cardIws, summary) {
        let cardIwTabs = [];
        if (summary) {
            let startOdo = null;
            let endOdo = null;
            if (cardIws.length > 0) {
                startOdo = cardIws[0].summary.startOdo;
                endOdo = cardIws[cardIws.length - 1].summary.endOdo;
            }
            cardIwTabs.push(
                {
                    "id": activitiesSummaryTabId,
                    "content": (
                        <InfoPanel
                            drive={summary.drive}
                            work={summary.work}
                            rest={summary.rest}
                            poa={summary.poa}
                            leftColor={summaryColor}
                            headingText={getPanelTitleFromType(null)}
                            isSummary={true}
                            startDate={props.date}
                            endDate={null}
                            startOdo={startOdo}
                            endOdo={endOdo}
                            allowAddComment={false}
                        />)
                }
            );
        }
        if (cardIws) {
            cardIwTabs.push(
                ...cardIws.map((c) => {
                    return (
                        {
                            "id": `CardIw${c.cardIwId}`,
                            "content": (
                                <InfoPanel
                                    drive={c.summary.drive}
                                    work={c.summary.work}
                                    rest={c.summary.rest}
                                    poa={c.summary.poa}
                                    startOdo={c.summary.startOdo}
                                    endOdo={c.summary.endOdo}
                                    driverName={c.driverName}
                                    leftColor={getColourFromType(c.vehicleActivityType)}
                                    headingText={getPanelTitleFromType(c.vehicleActivityType, c.journeyName)}
                                    isSummary={false}
                                    startDate={c.startDate}
                                    endDate={c.endDate}
                                    showBurger={true}
                                    cardIwId={c.cardIwId}
                                    initialComment={c.comment}
                                    onCommentChange={handleCardIwCommentUpdate}
                                    burgerItems={getActionsFromActivityType(c.vehicleActivityType, c.cardIwId)}
                                    allowAddComment={canUpdateComments}
                                />
                            )
                        }
                    );
                })
            );
        }
        setCardIwPanelData(cardIwTabs);
    }

    function onAssignDriverModalClose(driverId) {
        setShowAssignDriverModal(false);

        if (driverId) {
            props.refreshTachoData();
        }
        setCardIwForAction(null);
    }

    function onShowAssignDriverModalOpen(cardIwId) {
        setShowAssignDriverModal(true);
        changeCardIw(cardIwId);
    }

    function onUnassignDriverModalOpen(cardIwId) {
        setShowUnassignModal(true);
        changeCardIw(cardIwId);
    }

    function onUnassignDriverModalClose(unassigned) {
        setShowUnassignModal(false);
        if (unassigned) {
            props.refreshTachoData();
        }
        setCardIwForAction(null);
    }

    function onAssignJourneyChange(show, cardIwId) {
        setShowAssignJourneyModal(show);
        changeCardIw(cardIwId);
    }

    function assignJourneyClose(){
        props.refreshTachoData();
        setShowAssignJourneyModal(false)
    }

    function onUnassignJourneyModalChange(show, cardIwId) {
        setShowUnassignJourneyModal(show);
        changeCardIw(cardIwId);
    }

    function unassignJourneyClose(){
        props.refreshTachoData();
        setShowUnassignJourneyModal(false)
    }

    function changeCardIw(cardIwId) {
        let cardIwObject = props.activitiesApiResponse.cardIws.find((c) => c.cardIwId === cardIwId);
        setCardIwForAction(cardIwObject);
    }

    function getActionsFromActivityType(vehicleActivityType, cardIwId) {
        let actions = [];

        if (vehicleActivityType === vehicleUploadedDrivingNoCard && canAssignDrivers) {
            actions.push(<MenuItem onClick={() => onShowAssignDriverModalOpen(cardIwId)} text="Assign Driver" key={`${cardIwId}-mi-assign-driver`} />);
            actions.push(<MenuItem onClick={() => onAssignJourneyChange(true, cardIwId)} text="Assign Journey" key={"mi-assign-journey"} />);
        }
        if (vehicleActivityType === driverAssignedToVehicleData && canAssignDrivers) {
            actions.push(<MenuItem onClick={() => onUnassignDriverModalOpen(cardIwId)} text="Unassign Driver" key={`${cardIwId}-mi-unassign-driver`} />);
        }
        if (vehicleActivityType === assignedJourney && canAssignDrivers) {
            actions.push(<MenuItem onClick={() => onUnassignJourneyModalChange(true, cardIwId)} text="Unassign Journey" key={`${cardIwId}-mi-unassign-journey`} />);
        }

        return actions;
    }

    function onActivityTabChange(id) {
        setDisplayedActivitiesData(id);
        setHighlightedActivity(null);
    }

    function handleHighlightActivity(activity) {
        setHighlightedActivity(activity);
    }

    return (
        <div>
            <AssignDriverModal
                showModal={showAssignDriverModal}
                onCloseModal={onAssignDriverModalClose}
                assetDetails={props.assetDetails}
                date={props.date}
                cardIw={cardIwForAction}
            />
            <AssignJourneyModal
                showModal={showAssignJourneyModal}
                onCloseModal={assignJourneyClose}
                date={props.date}
                assetDetails={props.assetDetails}
                cardIwData={cardIwForAction}
            />
            <UnassignDriverModal
                isOpen={showUnassignModal}
                onClose={onUnassignDriverModalClose}
                cardIw={cardIwForAction}
                assetId={props.assetDetails.assetId.toString()}
            />
            <UnassignJourneyModal
                isOpen={showUnassignJourneyModal}
                onClose={unassignJourneyClose}
                assetId={props.assetDetails.assetId.toString()}
                cardIw={cardIwForAction}
            />
            <div>
                <div className="inline-items spacer-bottom">
                    <ReportList lastSearchPayload={{ filters: [{ "key": "assetId", "value": props.assetDetails.assetId }], "startDate": props.date, "endDate": props.date }} pageName="DayDetail-Activities" popoverUsePortal={false} onReportDownloadingChange={onReportDownloading} />
                    <GetFilesButton uploadsList={props.activitiesApiResponse.uploads} />
                </div>
                <NotificationInline intent="success" text="Your report has been queued, and will download as soon as it's ready." show={reportDownloading} />
            </div>
            <div id="graph">
                <ActivitiesGraph
                    activitiesList={displayedActivities}
                    undisplayedActivitiesList={undisplatedActivities}
                    isLoading={props.isLoading}
                    isSummary={activitiesSummaryTabId === currentActivityTab}
                    baseDate={props.date}
                    highlightedActivity={highlightedActivity}
                />
            </div>
            <div className="summary-tab-list">
                <ShowHide evaluator={!props.isLoading}
                    show={
                        <Tabs className="summary-tab-list" onChange={(p) => onActivityTabChange(p)} animate={false}>
                            {dataSelectTabs}
                        </Tabs>
                    }
                    hide={<Tabs className="summary-tab-list"><Tab className="summary-tab-item"><Panel className="summary-tab-item-disabled" color="#333333" >Loading...</Panel></Tab></Tabs>} />
            </div>
            <div id="content">
                <div id="listing">
                    <h3>Activities</h3>
                    <ActivitiesList activitiesList={displayedActivities} isLoading={props.isLoading} onActivitySelected={handleHighlightActivity} id="activities-tab-activities-list" />
                </div>
                <div id="info-panels">
                    <ShowHide evaluator={!props.isLoading}
                        hide={
                            <InfoPanel disabled={true} startDate={props.date} headingText="" />
                        } show={
                            <>
                                {cardIwPanelData.find((c) => c.id === currentActivityTab)?.content}
                                {harshBrakingPanelData}
                            </>
                        } />
                </div>
            </div>

        </div>
    )
}

ActivitiesTab.defaultProps = {
    lastSearchPayload: {},
    isLoading: false
};

ActivitiesTab.propTypes = {
    assetDetails: PropTypes.object.isRequired,
    date: PropTypes.string,
    lastSearchPayload: PropTypes.object,
    activitiesApiResponse: PropTypes.object,
    harshBrakingApiResponse: PropTypes.array,
    isLoading: PropTypes.bool,
    vehicleActivityTypes: PropTypes.array.isRequired,
    refreshTachoData: PropTypes.func.isRequired
};